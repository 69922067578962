import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useGetUser } from '../contexts';
import { db } from '../firebase';
import { PersonnelUser } from '../types';

export default function useFetchPersonnelByWorkItem(
  workItemId: string,
): [PersonnelUser[], boolean] {
  const user = useGetUser();
  const [personnel, setPersonnel] = useState<PersonnelUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user.companyId) {
      return;
    }

    let unsubscribe: any;

    const usersCollection = collection(db, 'users');
    const q = query(
      usersCollection,
      where('companyId', '==', user.companyId),
      where('workItems', 'array-contains', workItemId),
    );

    unsubscribe = onSnapshot(q, (userDocs) => {
      const personnel: PersonnelUser[] = [];

      userDocs.forEach((doc) => {
        personnel.push({
          uid: doc.id,
          ...doc.data(),
        } as PersonnelUser);
      });

      setPersonnel(personnel);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [user, workItemId]);

  return [personnel, isLoading];
}
