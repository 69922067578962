import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

interface IProps {
  projectId?: string;
  workItemNumber?: string;
}

export default function Breadcrumbs({ projectId }: IProps): JSX.Element {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Breadcrumb fontWeight="medium" fontSize={['sm', 'xl']}>
      {isMobile && (
        <BreadcrumbItem>
          <BreadcrumbLink
            as={Link}
            to={`/projects/${projectId}`}
            color="#00308C"
          >
            Project Home
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      <BreadcrumbItem isCurrentPage>
        <Text>Project Team</Text>
      </BreadcrumbItem>
    </Breadcrumb>
  );
}
