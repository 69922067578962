import { KeyboardEvent, useEffect, useState } from 'react';
import { Box, Button, Input, Text } from '@chakra-ui/react';
import { EditableRow } from '../';
import { useGetUser } from '../../contexts';
import { isAdminUser } from '../../utils';

interface IProps {
  initialValues?: string[];
  onChange: (items: string[]) => void;
  type: string;
}

export default function Items({
  initialValues = [],
  onChange,
  type,
}: IProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const [items, setItems] = useState<string[]>(initialValues);
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  useEffect(() => {
    onChange(items);
  }, [onChange, items]);

  function handleAddItem() {
    if (!value) {
      return;
    }

    const updateItems = [...items, value];

    setItems(updateItems);
    setValue('');
  }

  function handleRemoveItem(idx: number) {
    const itemsToUpdate = [...items];

    itemsToUpdate.splice(idx, 1);

    setItems(itemsToUpdate);
  }

  function handleOnKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddItem();
    }
  }

  function handleOnEdit(idx: number, newValue: string) {
    const itemsToUpdate = [...items];

    itemsToUpdate[idx] = newValue;

    setItems(itemsToUpdate);
  }

  return (
    <Box>
      <Text fontWeight="medium" mb={2}>
        {`${type}s`}
      </Text>

      {isAdmin && (
        <Box display="flex" gap={3} mb={3}>
          <Input
            placeholder={type}
            onChange={({ currentTarget }) => setValue(currentTarget.value)}
            onKeyDown={handleOnKeyDown}
            value={value}
            rounded="md"
            size="sm"
          />
          <Button
            flexShrink={0}
            onClick={handleAddItem}
            variant="outline"
            size="sm"
          >
            Add Item
          </Button>
        </Box>
      )}

      {items.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={6}
          border="1px dashed"
          borderColor="#CCCCCC"
          rounded="sm"
        >
          <Text color="#4F4F4F" fontSize="sm">
            No items have been added.
          </Text>
        </Box>
      )}

      {items.length > 0 && (
        <Box display="flex" flexDir="column" gap={2}>
          {items.map((item, idx) => (
            <EditableRow
              idx={idx}
              key={idx}
              isEditable={isAdmin}
              item={item}
              onEdit={handleOnEdit}
              onRemove={handleRemoveItem}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
