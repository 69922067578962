import { ChangeEvent, useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Image,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import dayjs from 'dayjs';
import { db, storage } from '../../firebase';
import { useGetUser } from '../../contexts';
import { IProject, ItemStatus } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateProjectForm({
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [customer, setCustomer] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();
  const navigate = useNavigate();
  const user = useGetUser();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const collectionRef = collection(db, 'projects');

    try {
      const { id } = await addDoc(collectionRef, {
        createdAt: new Date(),
        createdBy: user.uid,
        companyId: user.companyId,
        name,
        number,
        location,
        customer,
        startDate: formatDate(startDate),
        status: ItemStatus.NOT_STARTED,
        endDate: formatDate(endDate),
      } as Omit<IProject, 'id' | 'createdAt'>);

      if (file) {
        const storageRef = ref(storage, `projects/${id}`);

        try {
          await uploadBytes(storageRef, file);
        } catch (error) {
          toast({
            title: 'Uh Oh',
            description: 'An error occurred while uploading the project image.',
            status: 'error',
            variant: 'left-accent',
            position: 'top-right',
          });
        }
      }

      toast({
        title: 'Success',
        description: 'Project successfully created.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      navigate(`/projects/${id}`);
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: 'Uh Oh',
        description: 'An error occurred while creating the project.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      const imageSrc = URL.createObjectURL(fileUpload);

      setFile(fileUpload);
      setImagePreviewUrl(imageSrc);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create Project</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <VStack spacing={4} mb={4}>
              <FormControl isRequired>
                <FormLabel>Project Name</FormLabel>
                <Input
                  placeholder="Project Name"
                  onKeyDown={(event) =>
                    event.key === 'Enter' && event.preventDefault()
                  }
                  onChange={({ currentTarget }) => setName(currentTarget.value)}
                />
                <FormHelperText>
                  Title that best describes the project.
                </FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Project Number</FormLabel>
                <Input
                  placeholder="Project Number"
                  onKeyDown={(event) =>
                    event.key === 'Enter' && event.preventDefault()
                  }
                  onChange={({ currentTarget }) =>
                    setNumber(currentTarget.value)
                  }
                />
                <FormHelperText>
                  Contract number associated with the project.
                </FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Project Location</FormLabel>
                <Input
                  placeholder="Project Location"
                  onKeyDown={(event) =>
                    event.key === 'Enter' && event.preventDefault()
                  }
                  onChange={({ currentTarget }) =>
                    setLocation(currentTarget.value)
                  }
                />
                <FormHelperText>Where is the work taking place?</FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Project Customer</FormLabel>
                <Input
                  placeholder="Project Customer"
                  onKeyDown={(event) =>
                    event.key === 'Enter' && event.preventDefault()
                  }
                  onChange={({ currentTarget }) =>
                    setCustomer(currentTarget.value)
                  }
                />
                <FormHelperText>
                  Who is the work being performed for?
                </FormHelperText>
              </FormControl>

              <Box display="flex" gap={4} w="100%">
                <FormControl isRequired>
                  <FormLabel>Period of Performance Start Date</FormLabel>
                  <Input
                    type="date"
                    onKeyDown={(event) =>
                      event.key === 'Enter' && event.preventDefault()
                    }
                    onChange={({ currentTarget }) =>
                      setStartDate(currentTarget.value)
                    }
                  />
                  <FormHelperText>When will the work begin?</FormHelperText>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Period of Performance End Date</FormLabel>
                  <Input
                    type="date"
                    onKeyDown={(event) =>
                      event.key === 'Enter' && event.preventDefault()
                    }
                    onChange={({ currentTarget }) =>
                      setEndDate(currentTarget.value)
                    }
                  />
                  <FormHelperText>When will the work end?</FormHelperText>
                </FormControl>
              </Box>

              <FormControl>
                <FormLabel>Project Image</FormLabel>
                <Input type="file" onChange={handleImageUpload} />
                <FormHelperText>
                  What image represents the project?
                </FormHelperText>
              </FormControl>

              {imagePreviewUrl && (
                <Box w="100%">
                  <Image
                    src={imagePreviewUrl}
                    alt="logo-preview"
                    maxHeight={200}
                  />
                </Box>
              )}
            </VStack>

            <Box display="flex" gap={3} justifyContent="flex-end">
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Save Project
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function formatDate(date: string): Timestamp {
  const now = dayjs();

  return Timestamp.fromDate(
    dayjs(date).set('hour', now.hour()).set('minute', now.minute()).toDate(),
  );
}
