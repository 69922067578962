import { Box, Text } from '@chakra-ui/react';
import { IWorkItem, PersonnelUser } from '../../types';
import { PersonnelAvatar, TradeBadge, UserStatusBadge } from '../';
import PersonnelWorkItemAssignment from '../PersonnelTable/PersonnelWorkItemAssignment';

interface Props {
  hasBorder?: boolean;
  user: PersonnelUser;
  workItems?: IWorkItem[];
}

export default function UserCard({
  hasBorder = false,
  user,
  workItems,
}: Props): JSX.Element {
  const {
    badgeNumber,
    email,
    firstName,
    uid,
    lastName,
    rate,
    status,
    trade,
    workItems: workItemIds,
  } = user;

  const name = `${firstName} ${lastName}`;

  return (
    <Box
      border={hasBorder ? '1px solid' : undefined}
      borderColor="gray.200"
      bg="white"
      p={3}
      rounded="md"
    >
      <Box alignItems="center" display="flex" gap={2} mb={4}>
        <PersonnelAvatar id={uid} name={name} trade={trade} size="sm" />

        <Box>
          <Text fontWeight="medium">{name}</Text>
          <Text color="gray.500" fontSize="xs">
            {email}
          </Text>
        </Box>
      </Box>

      <Box
        display="grid"
        gap={4}
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr 1fr"
      >
        <Box>
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" mb={1}>
            Trade
          </Text>
          <TradeBadge trade={trade} />
        </Box>

        <Box>
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" mb={1}>
            Status
          </Text>
          <UserStatusBadge status={status} />
        </Box>

        <Box>
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" mb={1}>
            Badge Number
          </Text>

          <Text fontSize="sm">{badgeNumber}</Text>
        </Box>

        <Box>
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" mb={1}>
            Rate
          </Text>

          <Text fontSize="sm" textTransform="capitalize">
            {rate}
          </Text>
        </Box>

        <Box>
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" mb={1}>
            Work Item Assignments
          </Text>

          {workItems && (
            <PersonnelWorkItemAssignment
              workItems={workItems}
              workItemIds={workItemIds}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
