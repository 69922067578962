import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import WorkItemCard from './WorkItemCard';
import WorkItemBreadcrumbs from '../WorkItemBreadcrumbs';
import { CreateWorkItemForm, EmptyState, Loader } from '../../../components';
import { PlusIcon } from '../../../assets';
import { useFetchWorkItems } from '../../../hooks';
import { useGetUser } from '../../../contexts';
import { isAdminUser } from '../../../utils';

export default function WorkItemsList(): JSX.Element {
  const { id } = useParams();
  const [workItems, isLoading] = useFetchWorkItems(id);
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showEmptyState = !isLoading && workItems.length === 0;

  return (
    <Box>
      {isAdmin && (
        <CreateWorkItemForm isOpen={isOpen} onClose={onClose} projectId={id!} />
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <WorkItemBreadcrumbs projectId={id} />

        {isAdmin && (
          <Button
            leftIcon={<PlusIcon />}
            variant="primary"
            size="sm"
            onClick={onOpen}
          >
            Create Work Item
          </Button>
        )}
      </Box>

      <Box>
        {isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Loader />
          </Box>
        )}

        {showEmptyState && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <EmptyState
              description="Create a work item to begin work on this project."
              heading="No Work Items"
            />
          </Box>
        )}

        <Box
          display="grid"
          gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
          gap={8}
          flexWrap="wrap"
        >
          {workItems.map((workItem) => (
            <WorkItemCard
              key={workItem.id}
              projectId={id}
              workItem={workItem}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
