import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  Select,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { PersonnelAvatar } from '../';
import { db } from '../../firebase';
import { LeadTrade, PersonnelRate, PersonnelUser } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  personnel: PersonnelUser;
}

export default function EditPersonnelForm({
  isOpen,
  onClose,
  personnel,
}: IProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>(personnel.firstName);
  const [lastName, setLastName] = useState<string>(personnel.lastName);
  const [badgeNumber, setBadgeNumber] = useState<string>(personnel.badgeNumber);
  const [trade, setTrade] = useState<string>(personnel.trade);
  const [rate, setRate] = useState<string>(personnel.rate);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const userRef = doc(db, 'users', personnel.uid);

    try {
      await updateDoc(userRef, {
        firstName,
        lastName,
        badgeNumber: badgeNumber,
        rate,
        trade,
      } as Pick<PersonnelUser, 'firstName' | 'lastName' | 'badgeNumber' | 'rate' | 'trade'>);

      toast({
        title: 'Success',
        description: 'Personnel record updated.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while updating personnel.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Personnel</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <Box display="flex" justifyContent="center" mb={4}>
              <PersonnelAvatar
                id={personnel.uid}
                isEditable
                name={`${firstName} ${lastName}`}
                size="xl"
                trade={trade as LeadTrade}
              />
            </Box>

            <VStack spacing={2} mb={6}>
              <FormControl isRequired>
                <FormLabel fontSize="sm">First Name</FormLabel>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={({ currentTarget }) =>
                    setFirstName(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Last Name</FormLabel>
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={({ currentTarget }) =>
                    setLastName(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Badge Number</FormLabel>
                <Input
                  type="number"
                  placeholder="Badge Number"
                  value={badgeNumber}
                  onChange={({ currentTarget }) =>
                    setBadgeNumber(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Trade</FormLabel>
                <Select
                  placeholder="Select Trade"
                  value={trade}
                  onChange={({ currentTarget }) =>
                    setTrade(currentTarget.value)
                  }
                >
                  {Object.values(LeadTrade).map((trade) => (
                    <option key={trade} value={trade}>
                      {`${trade.charAt(0).toUpperCase()}${trade.slice(1)}`}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Rate</FormLabel>
                <Select
                  placeholder="Select Rate"
                  value={rate}
                  onChange={({ currentTarget }) => setRate(currentTarget.value)}
                >
                  {Object.values(PersonnelRate).map((rate) => (
                    <option key={rate} value={rate}>
                      {`${rate.charAt(0).toUpperCase()}${rate.slice(1)}`}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>

            <Box
              display="flex"
              gap={3}
              justifyContent="flex-end"
              marginTop="auto"
              pb={4}
            >
              <Box>
                <Button
                  variant="secondary"
                  onClick={onClose}
                  disabled={isSubmitting}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                >
                  Save Personnel
                </Button>
              </Box>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
