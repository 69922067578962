import { Box, Text, Tooltip } from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import Subtasks from './Subtasks';
import { ProgressSelect, StatusMenu } from '../../../../../components';
import { ITask, ItemStatus } from '../../../../../types';
import { db } from '../../../../../firebase';

interface Props {
  task: ITask;
}

export default function TaskCard({ task }: Props): JSX.Element {
  const { hoursAllocated, id, leadTrade, name, number, progress, status } =
    task;

  async function updateTaskStatus(status: ItemStatus) {
    const taskRef = doc(db, 'tasks', task.id);

    await updateDoc(taskRef, {
      status,
    });
  }

  return (
    <Box border="1px solid" borderColor="gray.200" rounded="md" p={2}>
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="flex"
        justifyContent="space-between"
        pb={1}
        mb={4}
      >
        <Box
          alignItems="center"
          display="flex"
          gap={1}
          fontSize="lg"
          fontWeight="semibold"
        >
          <Text>{number}.</Text>

          <Tooltip label={name}>
            <Text
              maxWidth="275px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {name}
            </Text>
          </Tooltip>
        </Box>
      </Box>

      <Box
        borderBottom="1px solid"
        borderColor="gray.200"
        display="grid"
        gap={1}
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows="1fr 1fr"
        mb={4}
      >
        <Box mb={2}>
          <Text fontSize="xs" fontWeight="semibold" mb={1}>
            Status
          </Text>

          <StatusMenu
            status={status}
            onChange={updateTaskStatus}
            width="inherit"
          />
        </Box>

        <Box mb={2}>
          <Text fontSize="xs" fontWeight="semibold" mb={1}>
            Progress
          </Text>

          <ProgressSelect progress={progress} taskId={id} />
        </Box>

        <Box mb={2}>
          <Text fontSize="xs" fontWeight="semibold" mb={1}>
            Hours Allocated
          </Text>

          <Text fontSize="sm">{hoursAllocated}</Text>
        </Box>

        <Box mb={2}>
          <Text fontSize="xs" fontWeight="semibold" mb={1}>
            Lead Trade
          </Text>

          <Text fontSize="sm" textTransform="capitalize">
            {leadTrade}
          </Text>
        </Box>
      </Box>

      <Subtasks task={task} />
    </Box>
  );
}
