import { Link, Td } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useGetProjects } from '../../contexts';

interface IProps {
  projectIds?: string[];
}

export default function PersonnelAssignment({
  projectIds,
}: IProps): JSX.Element {
  const projects = useGetProjects();

  return (
    <Td fontSize="sm">
      {(!projectIds || projectIds.length === 0) && 'No Project'}
      {projectIds?.map((projectId) => (
        <Link
          as={ReactRouterLink}
          key={projectId}
          to={`/projects/${projectId}`}
          textDecoration="underline"
        >
          {projects.find((project) => project.id === projectId)?.name}
        </Link>
      ))}
    </Td>
  );
}
