import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ITask } from '../types';

export async function updateTaskProgress(
  taskId: string,
  progress: number,
): Promise<void> {
  const taskRef = doc(db, `/tasks/${taskId}`);

  await updateDoc(taskRef, {
    progress,
  } as Pick<ITask, 'progress'>);
}
