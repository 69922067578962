import { Box, VStack } from '@chakra-ui/react';
import { Briefcase, Calendar, Clipboard, MapPin } from '../../assets';
import { formatProjectDuration } from '../../utils';
import { IProject } from '../../types';

interface IProps {
  project: IProject;
}

export default function ProjectDetails({ project }: IProps) {
  return (
    <VStack spacing={2} alignItems="flex-start" fontSize="xs">
      <Box display="flex" alignItems="center" gap={2} color="#4F4F4F">
        <Calendar /> {formatProjectDuration(project.startDate, project.endDate)}
      </Box>

      <Box display="flex" alignItems="center" gap={2} color="#4F4F4F">
        <Clipboard /> {project.number}
      </Box>

      <Box display="flex" alignItems="center" gap={2} color="#4F4F4F">
        <Briefcase /> {project.customer}
      </Box>

      <Box display="flex" alignItems="center" gap={2} color="#4F4F4F">
        <MapPin /> {project.location}
      </Box>
    </VStack>
  );
}
