import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useGetUser } from '../contexts';
import { db } from '../firebase';
import { IProject } from '../types';

export default function useFetchProjects(): [IProject[], boolean] {
  const user = useGetUser();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchProjects() {
      if (!user.companyId) {
        return;
      }

      const projectsRef = collection(db, 'projects');
      const q = query(projectsRef, where('companyId', '==', user.companyId));
      const projectDocs = await getDocs(q);
      const projects: IProject[] = [];

      projectDocs.forEach((doc) => {
        projects.push({
          id: doc.id,
          ...doc.data(),
        } as IProject);
      });

      setProjects(projects);
      setIsLoading(false);
    }

    fetchProjects();
  }, [user]);

  return [projects, isLoading];
}
