import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { verifyPasswordResetCode } from 'firebase/auth';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { PublicPageLayout } from '../../components';
import SetPasswordForm from './SetPasswordForm';
import { auth } from '../../firebase';

export default function InvitationPage(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    async function verifyCode() {
      if (!oobCode) {
        setHasError(true);
        setIsLoading(false);
        return;
      }

      try {
        const email = await verifyPasswordResetCode(auth, oobCode);
        setEmail(email);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    verifyCode();
  }, [oobCode]);

  return (
    <PublicPageLayout>
      {isLoading && (
        <Box alignItems="center" display="flex" flexDirection="column">
          <Spinner color="navyBlue" mb={2} size="lg" />
          <Text>Loading...</Text>
        </Box>
      )}
      {email && <SetPasswordForm email={email} oobCode={oobCode!} />}
      {hasError && (
        <Text color="red.500">
          An error occurred while verifying your invitation code. This can
          happen if your invitation has expired or if the code is invalid.
          Please contact your administrator to request a new invitation.
        </Text>
      )}
    </PublicPageLayout>
  );
}
