import { doc, updateDoc } from 'firebase/firestore';
import { Box, Button, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { StatusMenu } from '../../../../../components';
import { ChevronDown, ChevronUp } from '../../../../../assets';
import { ITask, ItemStatus } from '../../../../../types';
import { db } from '../../../../../firebase';

interface Props {
  task: ITask;
}

export default function Subtasks({ task }: Props): JSX.Element | null {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { subtasks } = task;

  if (subtasks.length === 0) {
    return null;
  }

  async function updateSubtaskStatus(idx: number, status: ItemStatus) {
    const taskRef = doc(db, 'tasks', task.id);
    const subtasksToUpdate = [...task.subtasks];
    subtasksToUpdate[idx].status = status;

    await updateDoc(taskRef, {
      subtasks: subtasksToUpdate,
    });
  }

  return (
    <Box>
      <Box mb={4}>
        <Button
          size="xs"
          variant="ghost"
          rightIcon={isOpen ? <ChevronUp /> : <ChevronDown />}
          onClick={isOpen ? onClose : onOpen}
          width="100%"
        >
          <Text fontSize="sm" fontWeight="semibold">
            {isOpen ? 'Hide' : 'Show'} Subtasks
          </Text>
        </Button>
      </Box>

      {isOpen && (
        <Box>
          {task.subtasks.map((subtask, idx) => (
            <Box
              alignItems="center"
              display="flex"
              key={idx}
              fontSize="sm"
              mb={2}
            >
              <Box display="flex" w="175px">
                <Text fontWeight="bold" mr={1}>
                  {task.number}.{idx + 1}
                </Text>

                <Tooltip label={subtask.name}>
                  <Text
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    w="252px"
                  >
                    {subtask.name}
                  </Text>
                </Tooltip>
              </Box>

              <StatusMenu
                status={subtask.status}
                onChange={(status) => updateSubtaskStatus(idx, status)}
                width="inherit"
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
