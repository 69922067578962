import { Box } from '@chakra-ui/react';
import { doc, setDoc } from 'firebase/firestore';
import { Items } from '../../../../components';
import { db } from '../../../../firebase';
import { IWorkItem } from '../../../../types';

interface IProps {
  workItem: IWorkItem;
}

export default function ResourcesTab({ workItem }: IProps): JSX.Element {
  const { requiredTools, materials, consumables } = workItem;

  async function handleChange(items: string[], type: string) {
    const workItemRef = doc(db, `workItems/${workItem.id}`);

    setDoc(
      workItemRef,
      {
        [type]: items,
      },
      { merge: true },
    );
  }

  return (
    <Box maxW={640} display="flex" flexDir="column" gap={4}>
      <Items
        initialValues={requiredTools}
        type="Required Tool"
        onChange={(items) => handleChange(items, 'requiredTools')}
      />
      <Items
        initialValues={materials}
        type="Material"
        onChange={(items) => handleChange(items, 'materials')}
      />
      <Items
        initialValues={consumables}
        type="Consumable"
        onChange={(items) => handleChange(items, 'consumables')}
      />
    </Box>
  );
}
