import { useMemo } from 'react';
import { Box, Td, Text, Tr } from '@chakra-ui/react';
import { UserAvatar, UserStatusBadge } from '../../../components';
import OptionsMenu from '../OptionsMenu';
import { AdminUser } from '../../../types';
import { getRoleName } from '../../../utils';

interface Props {
  user: AdminUser;
}

export default function TableRow({ user }: Props): JSX.Element {
  const { badgeNumber, email, firstName, lastName, role, status, uid } = user;
  const name = `${firstName} ${lastName}`;
  const userRole = useMemo(() => getRoleName(role), [role]);

  return (
    <Tr key={uid}>
      <Td display="flex" alignItems="center" gap={2}>
        <Box alignItems="center" display="flex" gap={2}>
          <UserAvatar user={user} size="sm" />

          <Box>
            <Text fontWeight="medium">{name}</Text>
            <Text color="gray.500" fontSize="xs">
              {email}
            </Text>
          </Box>
        </Box>
      </Td>
      <Td fontSize="sm" textTransform="capitalize">
        {userRole}
      </Td>
      <Td fontSize="sm">{badgeNumber}</Td>
      <Td>
        <UserStatusBadge status={status} />
      </Td>
      <Td textAlign="right">
        <OptionsMenu user={user} />
      </Td>
    </Tr>
  );
}
