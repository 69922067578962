import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase';
import { AdminUser, PersonnelUser } from '../types';

export * from './tasks';
export * from './users';

type NewAdminUserData = Omit<AdminUser, 'uid' | 'createdAt' | 'status'>;
type NewPersonnelUserData = Omit<PersonnelUser, 'uid' | 'createdAt' | 'status'>;

export async function inviteAdminUser(
  userData: NewAdminUserData,
): Promise<AdminUser | undefined> {
  const inviteAdminUser = httpsCallable<
    {
      userData: NewAdminUserData;
    },
    AdminUser | undefined
  >(functions, 'inviteAdminUser');

  const { data } = await inviteAdminUser({ userData });

  return data;
}

export async function invitePersonnelUser(
  personnelData: NewPersonnelUserData,
): Promise<PersonnelUser | undefined> {
  const invitePersonnelUser = httpsCallable<
    {
      personnelData: NewPersonnelUserData;
    },
    PersonnelUser | undefined
  >(functions, 'invitePersonnelUser');

  const { data } = await invitePersonnelUser({ personnelData });

  return data;
}

export async function onAcceptInvitation(
  uid: string,
  phoneNumber?: string,
): Promise<void> {
  const onAcceptInvitation = httpsCallable<
    {
      uid: string;
    },
    void
  >(functions, 'onAcceptInvitation');

  await onAcceptInvitation({ uid });
}

export async function resendInvitation(uid: string): Promise<void> {
  const resendInvitation = httpsCallable<
    {
      uid: string;
    },
    void
  >(functions, 'resendInvitation');

  await resendInvitation({ uid });
}

export async function smsLoginPreflight(phoneNumber: string): Promise<void> {
  const smsLoginPreflight = httpsCallable<
    {
      phoneNumber: string;
    },
    void
  >(functions, 'smsLoginPreflight');

  await smsLoginPreflight({ phoneNumber });
}

export async function updateAdminUser(
  updateParams: Omit<AdminUser, 'uid' | 'companyId' | 'createdAt' | 'status'>,
): Promise<void> {
  const updateAdminUser = httpsCallable<{
    updateParams: Omit<AdminUser, 'uid' | 'companyId' | 'createdAt' | 'status'>;
  }>(functions, 'updateAdminUser');

  await updateAdminUser({ updateParams });
}

export async function updateUserPhoneNumber(
  uid: string,
  phoneNumber: string | null,
): Promise<void> {
  const updateUserPhoneNumber = httpsCallable<
    {
      phoneNumber: string | null;
      uid: string;
    },
    void
  >(functions, 'updateUserPhoneNumber');

  await updateUserPhoneNumber({ phoneNumber, uid });
}
