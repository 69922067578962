import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from '@chakra-ui/react';
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  updatePhoneNumber,
} from 'firebase/auth';
import PhoneNumberInput from './PhoneNumberInput';
import { useGetUser } from '../../../contexts';
import { auth } from '../../../firebase';

export default function SmsLogin(): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [verificationId, setVerificationId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const phoneAuthProvider = new PhoneAuthProvider(auth);
  const user = useGetUser();
  const toast = useToast();

  useEffect(() => {
    const { phoneNumber } = auth.currentUser || {};

    if (phoneNumber) {
      const formattedPhoneNumber = phoneNumber.replace('+1', '');
      setPhoneNumber(formattedPhoneNumber);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'verify-sms-button',
      {
        size: 'invisible',
        callback: (response: any) => {
          console.log(response);
        },
      },
      auth,
    );
  }, []);

  async function handleOnSendSms() {
    setIsLoading(true);

    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneNumber,
        // @ts-ignore
        window.recaptchaVerifier,
      );

      setVerificationId(verificationId);
    } catch (error) {
      console.log(error);

      toast({
        title: 'Uh Oh',
        description:
          'An error occurred sending the SMS. Please try again later.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnConfirmCode() {
    if (!auth.currentUser || !verificationId) {
      return;
    }

    setIsLoading(true);

    try {
      const authCredential = PhoneAuthProvider.credential(verificationId, code);

      await updatePhoneNumber(auth.currentUser, authCredential);

      toast({
        title: 'Success',
        description: 'SMS successfully verified.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      setVerificationId(undefined);
    } catch (error: any) {
      console.log(error);

      toast({
        title: 'Uh Oh',
        description: 'An error occurred verifying the SMS. Please try again.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Heading fontWeight="medium" fontSize="2xl">
          SMS Login
        </Heading>
      </Box>

      <Box maxW={512}>
        {verificationId && (
          <Box maxW={512}>
            <FormControl mb={4}>
              <FormLabel>Code</FormLabel>
              <Input
                bg="white"
                placeholder="Code"
                onChange={({ currentTarget }) => setCode(currentTarget.value)}
                value={code}
              />
            </FormControl>
            <Button
              onClick={handleOnConfirmCode}
              isLoading={isLoading}
              variant="primary"
              w="100%"
            >
              Verify Code
            </Button>
          </Box>
        )}

        {!verificationId && (
          <PhoneNumberInput
            isLoading={isLoading}
            onChange={setPhoneNumber}
            onSubmit={handleOnSendSms}
            value={phoneNumber}
          />
        )}
      </Box>
    </Box>
  );
}
