import { Link, useNavigate } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { LogOutIcon, ProjectsIcon, Settings } from '../../../assets';
import { auth } from '../../../firebase';

interface Props {
  onClose: () => void;
}

export default function Menu({ onClose }: Props): JSX.Element {
  const navigate = useNavigate();

  async function handleLogout() {
    await signOut(auth);

    onClose();

    navigate('/');
  }

  function handleOnLinkClick() {
    onClose();
  }

  return (
    <Box
      bg="white"
      position="absolute"
      left={0}
      right={0}
      shadow="md"
      top="60px"
      zIndex={100}
    >
      <Box
        as={Link}
        to="/projects"
        onClick={handleOnLinkClick}
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="flex"
        gap={2}
        px={4}
        py={3}
      >
        <ProjectsIcon />
        <Text>Projects</Text>
      </Box>

      <Box
        as={Link}
        to="/settings"
        onClick={handleOnLinkClick}
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.200"
        display="flex"
        gap={2}
        px={4}
        py={3}
      >
        <Settings />
        <Text>Settings</Text>
      </Box>

      <Box
        onClick={handleLogout}
        alignItems="center"
        display="flex"
        gap={2}
        px={4}
        py={3}
      >
        <LogOutIcon />
        <Text>Log out</Text>
      </Box>
    </Box>
  );
}
