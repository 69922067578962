import { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { IWorkItem } from '../types';

export default function useFetchWorkItems(
  projectId?: string,
): [IWorkItem[], boolean] {
  const [workItems, setWorkItems] = useState<IWorkItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function fetchWorkItems() {
      if (!projectId) {
        return;
      }

      const workItemsRef = collection(db, 'workItems');
      const q = query(workItemsRef, where('projectId', '==', projectId));
      const workItemDocs = await getDocs(q);
      const workItems: IWorkItem[] = [];

      workItemDocs.forEach((doc) => {
        workItems.push({
          id: doc.id,
          ...doc.data(),
        } as IWorkItem);
      });

      setWorkItems(workItems);
      setIsLoading(false);
    }

    fetchWorkItems();
  }, [projectId]);

  return [workItems, isLoading];
}
