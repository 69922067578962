import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { auth } from '../../firebase';

export default function EmailLoginForm(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const navigate = useNavigate();

  async function handleLogin(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setHasError(false);
    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);

      navigate('/projects');
    } catch (error: any) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  return (
    <Box>
      <Text
        color="navyBlue"
        fontSize="lg"
        fontWeight="semibold"
        mb={6}
        textAlign="center"
      >
        Login with Email
      </Text>

      {hasError && (
        <Text color="red" mb={4}>
          Invalid email or password. Please try again.
        </Text>
      )}

      <form onSubmit={handleLogin}>
        <FormControl isRequired isInvalid={hasError}>
          <FormLabel fontSize="sm">Email</FormLabel>
          <Input
            placeholder="Email"
            type="email"
            mb={4}
            onChange={({ currentTarget }) => setEmail(currentTarget.value)}
          />
        </FormControl>

        <FormControl isRequired isInvalid={hasError} mb={4}>
          <FormLabel fontSize="sm">Password</FormLabel>
          <Input
            placeholder="Password"
            type="password"
            mb={4}
            onChange={({ currentTarget }) => setPassword(currentTarget.value)}
          />
        </FormControl>

        <Button
          bg="#00205B"
          color="white"
          w="100%"
          type="submit"
          isLoading={isLoading}
        >
          Continue
        </Button>
      </form>
    </Box>
  );
}
