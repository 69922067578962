import { ChangeEvent, useState } from 'react';
import { FormLabel, Input, Spinner, useToast } from '@chakra-ui/react';
import { Edit } from '../../assets';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';

interface Props {
  onUpdate: (url: string) => void;
  uid: string;
}

export default function UpdateUserImageButton({
  onUpdate,
  uid,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  async function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      setIsLoading(true);

      const imageSrc = URL.createObjectURL(fileUpload);
      const storageRef = ref(storage, `userImages/${uid}`);

      try {
        await uploadBytes(storageRef, fileUpload);

        toast({
          title: 'Success',
          description: 'Successfully updated profile picture.',
          status: 'success',
          variant: 'left-accent',
          position: 'top-right',
        });

        onUpdate(imageSrc);
      } catch (error) {
        toast({
          title: 'Uh Oh',
          description: 'Failed to upload profile picture. Please try again.',
          status: 'error',
          variant: 'left-accent',
          position: 'top-right',
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <FormLabel
        htmlFor="profileImage"
        alignItems="end"
        bottom={1}
        cursor="pointer"
        display="flex"
        justifyContent="center"
        left={0}
        m={0}
        position="absolute"
        right={0}
        top={0}
        zIndex={1000}
      >
        {isLoading ? (
          <Spinner color="white" size="xs" />
        ) : (
          <Edit color="white" fontSize="2xs" />
        )}
      </FormLabel>
      <Input
        type="file"
        id="profileImage"
        display="none"
        onChange={handleImageUpload}
      />
    </>
  );
}
