import { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import SettingsSidebar from './SettingsSidebar';
import AdminUsers from './AdminUsers';
import Profile from './Profile';
import SmsLogin from './SmsLogin';
import { isAdminUser } from '../../utils';
import { useGetUser } from '../../contexts';
import { PageLayout } from '../../components';

export enum SettingsTabs {
  AdminUsers = 'adminUsers',
  Profile = 'profile',
  SmsLogin = 'smsLogin',
}

export default function SettingsPage(): JSX.Element {
  const [tab, setTab] = useState<SettingsTabs>();
  const user = useGetUser();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const { role } = user;
    const isAdmin = isAdminUser(role);

    if (isAdmin) {
      setTab(SettingsTabs.Profile);
    } else {
      setTab(SettingsTabs.SmsLogin);
    }
  }, [user]);

  if (isMobile) {
    return (
      <PageLayout>
        <SmsLogin />
      </PageLayout>
    );
  }

  return (
    <PageLayout hasSidebar>
      <Box display="grid" gridTemplateColumns="250px auto">
        {tab && <SettingsSidebar onChangeTab={setTab} tab={tab} />}

        <Box px={6} py={4}>
          {tab === 'adminUsers' && <AdminUsers />}
          {tab === 'profile' && <Profile />}
          {tab === 'smsLogin' && <SmsLogin />}
        </Box>
      </Box>
    </PageLayout>
  );
}
