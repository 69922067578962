import { Box } from '@chakra-ui/react';
import { UserStatus } from '../../types';

interface Props {
  status: UserStatus;
}

const ColorByStatus: Record<UserStatus, string> = {
  [UserStatus.ACTIVE]: 'green.500',
  [UserStatus.PENDING]: 'yellow.500',
  [UserStatus.INACTIVE]: 'gray.500',
};

export default function UserStatusBadge({ status }: Props) {
  const bgColor = ColorByStatus[status];

  return (
    <Box
      bg={bgColor}
      color="white"
      display="inline-block"
      fontWeight="semibold"
      fontSize="xs"
      px={2}
      textTransform="capitalize"
      rounded="md"
    >
      {status}
    </Box>
  );
}
