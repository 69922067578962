import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { DeleteButton } from '../';
import { db } from '../../firebase';
import { IProject } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  project: IProject;
}

export default function EditProjectForm({
  isOpen,
  onClose,
  project,
}: IProps): JSX.Element {
  const [name, setName] = useState<string>(project.name);
  const [number, setNumber] = useState<string>(project.number);
  const [location, setLocation] = useState<string>(project.location);
  const [customer, setCustomer] = useState<string>(project.customer);
  const [startDate, setStartDate] = useState<string>(
    formatTimestampForInput(project.startDate),
  );
  const [endDate, setEndDate] = useState<string>(
    formatTimestampForInput(project.endDate),
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const toast = useToast();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const projectRef = doc(db, 'projects', project.id);

    try {
      await updateDoc(projectRef, {
        name,
        number,
        location,
        customer,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      } as Pick<IProject, 'name' | 'number' | 'location' | 'customer' | 'startDate' | 'endDate'>);

      toast({
        title: 'Success',
        description: 'Project successfully updated.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: 'Uh Oh',
        description: 'An error occurred while updating the project.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  async function handleDelete() {
    const projectRef = doc(db, 'projects', project.id);

    try {
      await deleteDoc(projectRef);

      navigate('/projects');

      toast({
        title: 'Success',
        description: 'Project deleted.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred deleting the project.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Project</DrawerHeader>

        <DrawerBody pb={4}>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <VStack spacing={4} mb={4}>
              <FormControl isRequired>
                <FormLabel>Project Name</FormLabel>
                <Input
                  placeholder="Project Name"
                  value={name}
                  onChange={({ currentTarget }) => setName(currentTarget.value)}
                />
                <FormHelperText>
                  Title that best describes the project.
                </FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Project Number</FormLabel>
                <Input
                  placeholder="Project Number"
                  value={number}
                  onChange={({ currentTarget }) =>
                    setNumber(currentTarget.value)
                  }
                />
                <FormHelperText>
                  Contract number associated with the project.
                </FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Project Location</FormLabel>
                <Input
                  placeholder="Project Location"
                  value={location}
                  onChange={({ currentTarget }) =>
                    setLocation(currentTarget.value)
                  }
                />
                <FormHelperText>Where is the work taking place?</FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Project Customer</FormLabel>
                <Input
                  placeholder="Project Customer"
                  value={customer}
                  onChange={({ currentTarget }) =>
                    setCustomer(currentTarget.value)
                  }
                />
                <FormHelperText>
                  Who is the work being performed for?
                </FormHelperText>
              </FormControl>

              <Box display="flex" gap={4} w="100%">
                <FormControl isRequired>
                  <FormLabel>Period of Performance Start Date</FormLabel>
                  <Input
                    type="date"
                    value={startDate}
                    onChange={({ currentTarget }) =>
                      setStartDate(currentTarget.value)
                    }
                  />
                  <FormHelperText>When will the work begin?</FormHelperText>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Period of Performance End Date</FormLabel>
                  <Input
                    type="date"
                    value={endDate}
                    onChange={({ currentTarget }) =>
                      setEndDate(currentTarget.value)
                    }
                  />
                  <FormHelperText>When will the work end?</FormHelperText>
                </FormControl>
              </Box>
            </VStack>

            <Box display="flex" justifyContent="space-between" mt="auto">
              <DeleteButton onDelete={handleDelete} />

              <Box>
                <Button
                  variant="secondary"
                  onClick={onClose}
                  disabled={isSubmitting}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                >
                  Save Project
                </Button>
              </Box>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function formatDate(date: string): Timestamp {
  const now = dayjs();

  return Timestamp.fromDate(
    dayjs(date).set('hour', now.hour()).set('minute', now.minute()).toDate(),
  );
}

function formatTimestampForInput(timestamp?: Timestamp): string {
  if (!timestamp) {
    return '';
  }

  return dayjs(timestamp.toDate()).format('YYYY-MM-DD');
}
