import { Box, Text } from '@chakra-ui/react';
import { Personnel } from '../../assets';
import { LeadTrade } from '../../types';
import { TRADE_COLORS } from '../../constants';

interface IProps {
  trade: LeadTrade;
}

export default function TradeBadge({ trade }: IProps): JSX.Element {
  return (
    <Box
      alignItems="center"
      bg={TRADE_COLORS[trade]}
      color="white"
      display="inline-flex"
      fontSize="xs"
      fontWeight="semibold"
      gap={1.5}
      px={2}
      rounded="md"
    >
      <Personnel fontSize="2xs" />

      <Text textTransform="capitalize">{trade}</Text>
    </Box>
  );
}
