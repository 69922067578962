import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  confirmPasswordReset,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { onAcceptInvitation } from '../../api';
import { auth } from '../../firebase';

interface Props {
  email: string;
  oobCode: string;
}

export default function SetPasswordForm({
  email,
  oobCode,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setHasErrorMsg] = useState<string | undefined>();
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await onAcceptInvitation(auth.currentUser!.uid);

        window.location.href = '/projects';
      }
    });
  }, []);

  async function handleOnSubmit(event: React.SyntheticEvent) {
    event.preventDefault();

    if (password.trim().length < 8) {
      setHasErrorMsg('Passwords must be at least 8 characters long.');
      return;
    }

    setHasErrorMsg(undefined);
    setIsLoading(true);

    try {
      await confirmPasswordReset(auth, oobCode!, password);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      setIsLoading(false);
      setHasErrorMsg(
        'Any error occurred accepting your invitation. Please try again or contact your administrator.',
      );
    }
  }

  return (
    <>
      <Box mb={6} textAlign="center">
        <Text color="#00205B" fontSize="lg" fontWeight="semibold" mb={2}>
          Accept Invitation
        </Text>
        <Text color="gray.500" fontSize="sm">
          You have been invited to join your team on Keelblok. Set a password
          for your account before accepting your invitation.
        </Text>
      </Box>

      {errorMsg && (
        <Text
          bg="red.50"
          border="2px solid"
          borderColor="red.500"
          color="red"
          fontSize="sm"
          mb={4}
          p={1}
          px={2}
          rounded="md"
        >
          <Text fontWeight="semibold" as="span" mr={1}>
            Uh Oh.
          </Text>
          {errorMsg}
        </Text>
      )}

      <form onSubmit={handleOnSubmit}>
        <FormControl id="email" mb={6}>
          <FormLabel>Email</FormLabel>
          <Input type="email" value={email} readOnly />
        </FormControl>

        <FormControl id="password" mb={6} isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            placeholder="password"
            type="password"
            onChange={({ currentTarget }) => setPassword(currentTarget.value)}
          />
        </FormControl>

        <Button type="submit" variant="primary" w="100%" isLoading={isLoading}>
          Continue
        </Button>
      </form>
    </>
  );
}
