import { ITask } from '../types';

export default function projectCompletionRatio(tasks: ITask[]): number {
  if (tasks.length === 0) {
    return 0;
  }

  const sumOfTasksProgress = tasks.reduce((memo, val) => {
    return memo + val.progress;
  }, 0);

  return Math.floor(sumOfTasksProgress / tasks.length);
}
