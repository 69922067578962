import {
  Drawer as ChakraDrawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export default function Drawer({
  children,
  isOpen,
  onClose,
  title,
}: Props): JSX.Element {
  return (
    <ChakraDrawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>

        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
}
