import {
  Box,
  Button,
  Heading,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import {
  EditProjectForm,
  ProjectDetails,
  ProjectImage,
  ProjectProgressCircle,
  StatusMenu,
} from '../../components';
import NavItem from './NavItem';
import { useSubscribeToProject } from '../../hooks';
import { useGetUser } from '../../contexts';
import { isAdminUser } from '../../utils';
import { Edit } from '../../assets';
import { ItemStatus } from '../../types';
import { db } from '../../firebase';

interface IProps {
  projectId?: string;
}

export default function ProjectSidebar({ projectId }: IProps): JSX.Element {
  const [project] = useSubscribeToProject(projectId);
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (!project) {
    // @TODO: Handle sidebar loading
    return <Box />;
  }

  async function handleStatusChange(status: ItemStatus) {
    if (!project) {
      return;
    }

    const projectRef = doc(db, 'projects', project?.id);

    await updateDoc(projectRef, {
      status,
    });
  }

  return (
    <>
      {isAdmin && (
        <EditProjectForm isOpen={isOpen} onClose={onClose} project={project} />
      )}

      <Box
        bg="white"
        display="flex"
        flexDir="column"
        alignItems="center"
        borderRight={isMobile ? undefined : '1px solid'}
        borderColor="#CCCCCC"
        rounded={isMobile ? 'md' : undefined}
      >
        <Box
          mb={isMobile ? 4 : 0}
          p={isMobile ? 0 : 5}
          pt={isMobile ? 4 : undefined}
          w="100%"
        >
          <ProjectImage isEditable={isAdmin} projectId={project.id} />

          <Heading
            fontSize="lg"
            fontWeight="normal"
            mt={4}
            textAlign={isMobile ? 'center' : 'inherit'}
          >
            {project.name}
          </Heading>
        </Box>

        <Box w="100%">
          <NavItem page="workItems" projectId={project.id} />
          <NavItem page="team" projectId={project.id} />
        </Box>

        <Box borderTop="1px solid" borderColor="#CCCCCC" w="100%" my={4} />

        <Box w="100%" px={5}>
          <Box mb={6}>
            <Heading size="xs" w="100%" mb={2}>
              Status
            </Heading>
            <StatusMenu
              hasBorder
              isDisabled={!isAdmin}
              onChange={handleStatusChange}
              status={project.status}
            />
          </Box>

          <Box mb={6}>
            <Heading size="xs" w="100%" mb={2}>
              Progress
            </Heading>
            <Box display="flex" justifyContent="center">
              <ProjectProgressCircle id={project.id} />
            </Box>
          </Box>

          <Box mb={6}>
            <Heading size="xs" w="100%" mb={2}>
              Details
            </Heading>
            <ProjectDetails project={project} />
          </Box>

          {isAdmin && (
            <>
              <Heading size="xs" w="100%" mb={2}>
                Actions
              </Heading>

              <Button
                leftIcon={<Edit />}
                size="sm"
                w="100%"
                variant="outline"
                onClick={onOpen}
              >
                Edit Project
              </Button>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
