import { useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { Items } from '../';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { useGetUser } from '../../contexts';
import { ItemStatus } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
}

export default function CreateWorkItemForm({
  isOpen,
  onClose,
  projectId,
}: IProps): JSX.Element {
  const [workItemNumber, setWorkItemNumber] = useState<string>('');
  const [requiredTools, setRequiredTools] = useState<string[]>([]);
  const [consumables, setConsumables] = useState<string[]>([]);
  const [materials, setMaterials] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();
  const navigate = useNavigate();
  const user = useGetUser();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const workItemsRef = collection(db, 'workItems');

    try {
      const { id } = await addDoc(workItemsRef, {
        consumables,
        companyId: user.companyId,
        createdAt: new Date(),
        createdBy: user.uid,
        materials,
        number: workItemNumber,
        projectId,
        requiredTools,
        status: ItemStatus.NOT_STARTED,
      });

      toast({
        title: 'Success',
        description: 'Work item successfully created.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      navigate(`/projects/${projectId}/items/${id}`);
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: 'Uh Oh',
        description: 'An error occurred while creating the work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  function handleOnChange(
    items: string[],
    callback: (items: string[]) => void,
  ) {
    callback(items);
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create Work Item</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <FormControl isRequired mb={4}>
              <FormLabel>Work Item Number</FormLabel>
              <Input
                placeholder="Work Item Number"
                onChange={({ currentTarget }) =>
                  setWorkItemNumber(currentTarget.value)
                }
              />
            </FormControl>

            <Box mb={4}>
              <Items
                onChange={(items) => handleOnChange(items, setRequiredTools)}
                type="Required Tool"
              />
            </Box>

            <Box mb={4}>
              <Items
                onChange={(items) => handleOnChange(items, setConsumables)}
                type="Consumable"
              />
            </Box>

            <Box mb={4}>
              <Items
                onChange={(items) => handleOnChange(items, setMaterials)}
                type="Material"
              />
            </Box>

            <Box display="flex" gap={3} justifyContent="flex-end">
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Create Work Item
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
