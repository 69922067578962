import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import {
  InvitationPage,
  LoginPage,
  PersonnelPage,
  ProjectPage,
  ProjectsPage,
  RequestPasswordResetPage,
  SettingsPage,
} from './pages';
import { ProtectedRoute } from './components';
import { useGetUser } from './contexts';
import { isAdminUser } from './utils';

export default function App(): JSX.Element {
  const user = useGetUser();
  const location = useLocation();
  const posthog = usePostHog();
  const isAdmin = isAdminUser(user.role);

  useEffect(() => {
    posthog?.capture('$pageview');
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />

      <Route path="/invitation" element={<InvitationPage />} />

      <Route
        path="/request-password-reset"
        element={<RequestPasswordResetPage />}
      />

      <Route
        path="/projects"
        element={
          <ProtectedRoute>
            <ProjectsPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/projects/:id/*"
        element={
          <ProtectedRoute>
            <ProjectPage />
          </ProtectedRoute>
        }
      />

      {isAdmin && (
        <Route
          path="/team"
          element={
            <ProtectedRoute>
              <PersonnelPage />
            </ProtectedRoute>
          }
        />
      )}

      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
