import { Box, useDisclosure } from '@chakra-ui/react';
import Menu from './Menu';
import { Close, Menu as MenuIcon } from '../../../assets';

export default function MobileMenu(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box p={1}>
      {isOpen ? (
        <Close fontSize="xl" onClick={onClose} />
      ) : (
        <MenuIcon fontSize="xl" onClick={onOpen} />
      )}
      {isOpen && <Menu onClose={onClose} />}
    </Box>
  );
}
