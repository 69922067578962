import { SyntheticEvent, useMemo, useState } from 'react';
import { Select, useToast } from '@chakra-ui/react';
import { updateTaskProgress } from '../../api';

interface Props {
  progress: number;
  taskId: string;
}

const Options = () => (
  <>
    {Array.from({ length: 101 }, (_, i) => (
      <option key={i} value={i}>
        {i}%
      </option>
    )).reverse()}
  </>
);

export default function ProgressSelect({
  progress,
  taskId,
}: Props): JSX.Element {
  const [value, setValue] = useState<number>(progress);
  const toast = useToast();

  async function handleOnChange({
    currentTarget,
  }: SyntheticEvent<HTMLSelectElement>) {
    const newValue = Number(currentTarget.value);

    try {
      await updateTaskProgress(taskId, newValue);
      setValue(newValue);
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'Failed to update task progress. Please try again.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <Select size="sm" value={value} onChange={handleOnChange} rounded="md">
      <Options />
    </Select>
  );
}
