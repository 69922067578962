import { Box, useMediaQuery } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import { PersonnelTable, UserCard } from '../../../components';
import { useFetchPersonnelByProject, useFetchWorkItems } from '../../../hooks';

export default function ProjectTeam(): JSX.Element {
  const { id } = useParams();
  const [personnel] = useFetchPersonnelByProject(id);
  const [workItems] = useFetchWorkItems(id);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Breadcrumbs projectId={id} />
      </Box>

      {isMobile && (
        <Box>
          {personnel.map((person) => (
            <Box key={person.uid} mb={2} shadow="md">
              <UserCard user={person} workItems={workItems} />
            </Box>
          ))}
        </Box>
      )}

      {!isMobile && (
        <PersonnelTable
          personnel={personnel}
          showSearch={false}
          workItems={workItems}
        />
      )}
    </Box>
  );
}
