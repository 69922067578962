import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

interface IProps {
  projectId?: string;
  workItemNumber?: string;
}

export default function WorkItemBreadcrumbs({
  projectId,
  workItemNumber,
}: IProps): JSX.Element {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Breadcrumb fontWeight="medium" fontSize={['sm', 'xl']}>
      {isMobile && (
        <BreadcrumbItem>
          <BreadcrumbLink
            as={Link}
            to={`/projects/${projectId}`}
            color="#00308C"
          >
            Project Home
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={`/projects/${projectId}/items`}
          color="#00308C"
        >
          Work Items
        </BreadcrumbLink>
      </BreadcrumbItem>

      {workItemNumber && (
        <BreadcrumbItem isCurrentPage>
          <Text>{workItemNumber}</Text>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}
