import { useState } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import { StatusBadge } from '../';
import { Check, ChevronDown } from '../../assets';
import { ItemStatus } from '../../types';

interface Props {
  onChange: (status: ItemStatus) => Promise<void>;
  hasBorder?: boolean;
  isDisabled?: boolean;
  size?: string;
  status: ItemStatus;
  width?: string;
}

export default function StatusMenu({
  onChange,
  hasBorder = false,
  isDisabled = false,
  size,
  status,
  width = '100%',
}: Props): JSX.Element {
  const [currentStatus, setCurrentStatus] = useState<ItemStatus>(status);
  const toast = useToast();

  async function handleClick(newStatus: ItemStatus) {
    setCurrentStatus(newStatus);

    try {
      await onChange(newStatus);
    } catch (error) {
      setCurrentStatus(status);
      toast({
        title: 'Uh Oh',
        description:
          'An error occurred while changing the status of this item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
      console.error(error);
    }
  }

  if (isDisabled) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent={hasBorder ? 'space-between' : ''}
        bg="white"
        w={width}
        border={hasBorder ? '1px solid' : ''}
        borderColor={hasBorder ? 'gray.200' : ''}
        rounded="md"
        p={hasBorder ? 1 : 0}
        pr={2}
      >
        <StatusBadge status={currentStatus} hasBorder={false} size={size} />
      </Box>
    );
  }

  return (
    <Menu>
      <MenuButton
        bg="white"
        w={width}
        border={hasBorder ? '1px solid' : ''}
        borderColor={hasBorder ? 'gray.200' : ''}
        rounded="md"
        p={hasBorder ? 1 : 0}
        pr={2}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={hasBorder ? 'space-between' : ''}
        >
          <StatusBadge status={currentStatus} hasBorder={false} size={size} />
          <ChevronDown />
        </Box>
      </MenuButton>

      <MenuList>
        <MenuItem
          value={ItemStatus.NOT_STARTED}
          justifyContent="space-between"
          pr={4}
          onClick={() => handleClick(ItemStatus.NOT_STARTED)}
        >
          <StatusBadge
            status={ItemStatus.NOT_STARTED}
            hasBorder={false}
            size="xs"
          />

          {currentStatus === ItemStatus.NOT_STARTED && <Check fontSize="xs" />}
        </MenuItem>

        <MenuItem
          value={ItemStatus.IN_PROGRESS}
          justifyContent="space-between"
          pr={4}
          onClick={() => handleClick(ItemStatus.IN_PROGRESS)}
        >
          <StatusBadge
            status={ItemStatus.IN_PROGRESS}
            hasBorder={false}
            size="xs"
          />

          {currentStatus === ItemStatus.IN_PROGRESS && <Check fontSize="xs" />}
        </MenuItem>

        <MenuItem
          value={ItemStatus.SUSPENDED}
          justifyContent="space-between"
          pr={4}
          onClick={() => handleClick(ItemStatus.SUSPENDED)}
        >
          <StatusBadge
            status={ItemStatus.SUSPENDED}
            hasBorder={false}
            size="xs"
          />

          {currentStatus === ItemStatus.SUSPENDED && <Check fontSize="xs" />}
        </MenuItem>

        <MenuItem
          value={ItemStatus.COMPLETE}
          justifyContent="space-between"
          pr={4}
          onClick={() => handleClick(ItemStatus.COMPLETE)}
        >
          <StatusBadge
            status={ItemStatus.COMPLETE}
            hasBorder={false}
            size="xs"
          />

          {currentStatus === ItemStatus.COMPLETE && <Check fontSize="xs" />}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
