import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { Box, Button, Text } from '@chakra-ui/react';
import { auth } from '../../firebase';
import { Activity } from '../../assets';

export default function InactiveAlert(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function handleSignOut() {
    setIsLoading(true);

    await signOut(auth);
    navigate('/');
  }

  return (
    <Box
      alignItems="center"
      bg="gray.50"
      display="flex"
      h="100vh"
      justifyContent="center"
      p={4}
    >
      <Box
        alignItems="center"
        bg="red.50"
        border="2px solid"
        borderColor="red.500"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p={8}
        rounded="md"
        shadow="md"
        maxWidth={512}
      >
        <Activity color="red.500" fontSize="2xl" mb={4} />

        <Text color="red.500" fontWeight="semibold" fontSize="xl" mb={2}>
          Inactive Account
        </Text>

        <Text color="gray.500" textAlign="center" mb={8}>
          Your Keelblok account is inactive. Please contact your administrator
          to reactivate it.
        </Text>

        <Button
          onClick={handleSignOut}
          isLoading={isLoading}
          variant="outline"
          size="sm"
        >
          Sign Out
        </Button>
      </Box>
    </Box>
  );
}
