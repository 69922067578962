import { useState } from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import TaskRow from './TaskRow';
import { ITask } from '../../types';
import { EditTaskForm } from '../';

interface IProps {
  tasks: ITask[];
}

export default function TasksTable({ tasks }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [task, setTask] = useState<ITask | undefined>();

  function handleOnEdit(task: ITask) {
    setTask(task);
    onOpen();
  }

  function handleOnClose() {
    onClose();
    setTask(undefined);
  }

  return (
    <Box>
      {task && (
        <EditTaskForm isOpen={isOpen} onClose={handleOnClose} task={task} />
      )}

      <Box
        display="grid"
        gridTemplateColumns="300px 130px 125px 125px auto min-content"
        fontSize="xs"
        fontWeight="bold"
        mb={2}
        pl={2}
      >
        <Text>Name</Text>
        <Text>Status</Text>
        <Text>Progress</Text>
        <Text>Hours Allocated</Text>
        <Text>Lead Trade</Text>
      </Box>

      {tasks.map((task, idx) => (
        <TaskRow task={task} idx={idx} key={idx} onEdit={handleOnEdit} />
      ))}
    </Box>
  );
}
