import { ChangeEvent, useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Image,
  Input,
  useToast,
  VStack,
  Select,
} from '@chakra-ui/react';
import { ref, uploadBytes } from 'firebase/storage';
import { invitePersonnelUser } from '../../api';
import { storage } from '../../firebase';
import { useGetUser } from '../../contexts';
import { UserRole, LeadTrade, PersonnelRate } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PersonnelForm({
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [badgeNumber, setBadgeNumber] = useState<string>('');
  const [trade, setTrade] = useState<LeadTrade | undefined>(undefined);
  const [rate, setRate] = useState<PersonnelRate | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');

  const toast = useToast();
  const user = useGetUser();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!user || !trade || !rate) {
      return;
    }

    setIsLoading(true);

    try {
      const personnel = await invitePersonnelUser({
        badgeNumber,
        companyId: user.companyId,
        email,
        firstName,
        lastName,
        trade,
        rate,
        role: UserRole.PERSONNEL,
      });

      if (file && personnel) {
        const storageRef = ref(storage, `userImages/${personnel.uid}`);

        try {
          await uploadBytes(storageRef, file);
        } catch (error) {
          toast({
            title: 'Uh Oh',
            description:
              'An error occurred while uploading the personnel image.',
            status: 'error',
            variant: 'left-accent',
            position: 'top-right',
          });
        }
      }

      toast({
        title: 'Success',
        description: `${firstName} ${lastName} has been invited.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      handleOnClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while inviting personnel.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  }

  function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      const imageSrc = URL.createObjectURL(fileUpload);

      setFile(fileUpload);
      setImagePreviewUrl(imageSrc);
    }
  }

  function handleOnClose() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setBadgeNumber('');
    setTrade(undefined);
    setRate(undefined);
    setImagePreviewUrl('');
    setFile(undefined);
    onClose();
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleOnClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Invite Teammate</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <VStack spacing={4} mb={6}>
              <FormControl isRequired>
                <FormLabel fontSize="sm">First Name</FormLabel>
                <Input
                  placeholder="First Name"
                  onChange={({ currentTarget }) =>
                    setFirstName(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Last Name</FormLabel>
                <Input
                  placeholder="Last Name"
                  onChange={({ currentTarget }) =>
                    setLastName(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Email</FormLabel>
                <Input
                  placeholder="Email"
                  onChange={({ currentTarget }) =>
                    setEmail(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Badge Number</FormLabel>
                <Input
                  type="number"
                  placeholder="Badge Number"
                  onChange={({ currentTarget }) =>
                    setBadgeNumber(currentTarget.value)
                  }
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Trade</FormLabel>
                <Select
                  placeholder="Select Trade"
                  onChange={({ currentTarget }) =>
                    setTrade(currentTarget.value as LeadTrade)
                  }
                >
                  {Object.values(LeadTrade).map((trade) => (
                    <option key={trade} value={trade}>
                      {`${trade.charAt(0).toUpperCase()}${trade.slice(1)}`}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Rate</FormLabel>
                <Select
                  placeholder="Select Rate"
                  onChange={({ currentTarget }) =>
                    setRate(currentTarget.value as PersonnelRate)
                  }
                >
                  {Object.values(PersonnelRate).map((rate) => (
                    <option key={rate} value={rate}>
                      {`${rate.charAt(0).toUpperCase()}${rate.slice(1)}`}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Profile Picture</FormLabel>
                <Input type="file" onChange={handleImageUpload} />
              </FormControl>

              {imagePreviewUrl && (
                <Box w="100%">
                  <Image
                    src={imagePreviewUrl}
                    alt="logo-preview"
                    maxHeight={200}
                  />
                </Box>
              )}
            </VStack>

            <Box
              display="flex"
              gap={3}
              justifyContent="flex-end"
              marginTop="auto"
              pb={4}
            >
              <Button
                variant="secondary"
                onClick={handleOnClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isLoading}>
                Send Invite
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
