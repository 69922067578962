import { useState } from 'react';
import { Box, Button, Input, Text, useDisclosure } from '@chakra-ui/react';
import { ConfirmationDialog } from '../';
import { Edit, TrashIcon } from '../../assets';

interface IProps {
  idx: number;
  isEditable?: boolean;
  item: string;
  onEdit: (idx: number, newValue: string) => void;
  onRemove: (idx: number) => void;
}

export default function EditableRow({
  idx,
  item,
  isEditable = true,
  onEdit,
  onRemove,
}: IProps): JSX.Element {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [itemValue, setItemValue] = useState<string>(item);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleOnSave() {
    onEdit(idx, itemValue);
    setIsEditing(false);
  }

  function handleOnCancel() {
    setItemValue(item);
    setIsEditing(false);
  }

  function handleRemoveItem() {
    onRemove(idx);
    onClose();
  }

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleRemoveItem}
      />

      <Box
        alignItems="center"
        display="flex"
        border="1px solid"
        borderColor="#CCCCCC"
        fontSize="sm"
        justifyContent="space-between"
        key={item}
        pl={3}
        pr={2}
        py={2}
        rounded="md"
      >
        {isEditing && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            gap={8}
          >
            <Input
              size="sm"
              defaultValue={itemValue}
              autoFocus
              rounded="md"
              onChange={({ currentTarget }) =>
                setItemValue(currentTarget.value)
              }
            />

            <Box display="flex" alignItems="center" gap={1}>
              <Button size="sm" variant="outline" onClick={handleOnSave}>
                Save
              </Button>
              <Button size="sm" variant="ghost" onClick={handleOnCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        )}

        {!isEditing && (
          <>
            <Box display="flex" gap={1} alignItems="center">
              <Text fontWeight="medium" color="#4F4F4F">
                {idx + 1}.
              </Text>
              {item}
            </Box>

            {isEditable && (
              <Box>
                <Button
                  leftIcon={<Edit />}
                  size="xs"
                  variant="ghost"
                  onClick={() => setIsEditing(true)}
                  mr={1}
                >
                  Edit
                </Button>

                <Button
                  colorScheme="red"
                  size="xs"
                  onClick={onOpen}
                  variant="ghost"
                >
                  <TrashIcon />
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}
