import { ItemStatus } from '../types';

const badgeColorByStatus: Record<ItemStatus, string> = {
  [ItemStatus.IN_PROGRESS]: '#FFEA79',
  [ItemStatus.NOT_STARTED]: '#D9D9D9',
  [ItemStatus.SUSPENDED]: '#F4970B',
  [ItemStatus.COMPLETE]: '#5FBE68',
};

const textByStatus: Record<ItemStatus, string> = {
  [ItemStatus.IN_PROGRESS]: 'In Progress',
  [ItemStatus.NOT_STARTED]: 'Not Started',
  [ItemStatus.SUSPENDED]: 'Suspended',
  [ItemStatus.COMPLETE]: 'Complete',
};

export function getStatusBadgeColor(status: ItemStatus): string {
  return badgeColorByStatus[status];
}

export function formatStatusText(status: ItemStatus): string {
  return textByStatus[status];
}
