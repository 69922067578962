import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { PublicPageLayout } from '../../components';
import { auth } from '../../firebase';

export default function RequestPasswordResetPage(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  async function handleOnSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email.trim()) {
      return;
    }

    setIsLoading(true);

    await sendPasswordResetEmail(auth, email);

    setShowSuccess(true);
    setIsLoading(false);
  }

  return (
    <PublicPageLayout>
      <Box>
        <Text
          color="navyBlue"
          fontSize="lg"
          fontWeight="semibold"
          mb={6}
          textAlign="center"
        >
          Request Password Reset
        </Text>

        {showSuccess && (
          <Text
            bg="green.50"
            border="2px solid"
            borderColor="green.500"
            color="green"
            fontSize="sm"
            mb={4}
            p={1}
            px={2}
            rounded="md"
          >
            <Text fontWeight="semibold" as="span" mr={1}>
              Success!
            </Text>
            If an account with that email exists, we have sent you an email with
            instructions on how to reset your password.
          </Text>
        )}

        {!showSuccess && (
          <Box mb={8}>
            <form onSubmit={handleOnSubmit}>
              <FormControl isRequired>
                <FormLabel fontSize="sm">Email</FormLabel>
                <Input
                  placeholder="Email"
                  type="email"
                  mb={4}
                  onChange={({ currentTarget }) =>
                    setEmail(currentTarget.value)
                  }
                />
              </FormControl>

              <Button
                bg="#00205B"
                color="white"
                w="100%"
                type="submit"
                isLoading={isLoading}
              >
                Continue
              </Button>
            </form>
          </Box>
        )}

        <Box fontSize="sm" textAlign="center">
          Return the the login page{' '}
          <Link
            as={ReactRouterLink}
            to="/"
            color="navyBlue"
            fontWeight="semibold"
          >
            here
          </Link>
          .
        </Box>
      </Box>
    </PublicPageLayout>
  );
}
