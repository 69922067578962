import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import InactiveAlert from './InactiveAlert';
import { useGetUser } from '../../contexts';
import { auth } from '../../firebase';
import { UserStatus } from '../../types';

interface IProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  const user = useGetUser();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/');
    }
  }, [isLoading, isAuthenticated, navigate]);

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setIsLoading(false);
    });

    return subscriber();
  }, []);

  if (isLoading) {
    return <></>;
  }

  const { status } = user;

  if (status === UserStatus.INACTIVE) {
    return <InactiveAlert />;
  }

  return <>{children}</>;
}
