import { useState } from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
} from '@chakra-ui/react';
import { updateTaskProgress } from '../../api';

interface IProps {
  progress: number;
  taskId: string;
}

export default function ProgressInput({
  progress,
  taskId,
}: IProps): JSX.Element {
  const [value, setValue] = useState<string>(String(progress));
  const toast = useToast();

  function formatValue(value: string): string {
    return `${value}%`;
  }

  function parseValue(value: string): string {
    return value.replace('%', '');
  }

  async function handleOnBlur() {
    try {
      await updateTaskProgress(taskId, Number(value));
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while updating the task.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <NumberInput
      min={0}
      max={100}
      size="xs"
      maxW="100px"
      value={formatValue(value)}
      onChange={(valueString) => setValue(parseValue(valueString))}
      onBlur={handleOnBlur}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
}
