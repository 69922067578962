import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { PatternFormat } from 'react-number-format';

interface Props {
  isLoading: boolean;
  onChange: (value: string) => void;
  onSubmit: () => Promise<void>;
  value?: string;
}

export default function PhoneNumberInput({
  isLoading,
  onChange,
  onSubmit,
  value,
}: Props): JSX.Element {
  return (
    <Box maxW={512}>
      <FormControl mb={4}>
        <FormLabel>Phone Number</FormLabel>
        <Input
          as={PatternFormat}
          bg="white"
          format="+1 ###-###-####"
          placeholder="Phone number"
          onChange={({ currentTarget }) => onChange(currentTarget.value)}
          value={value}
        />
        <FormHelperText>Add a phone number to enable SMS login.</FormHelperText>
      </FormControl>
      <Button
        id="verify-sms-button"
        isLoading={isLoading}
        onClick={onSubmit}
        variant="primary"
        w="100%"
      >
        Send SMS
      </Button>
    </Box>
  );
}
