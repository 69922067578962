import { Box, useMediaQuery } from '@chakra-ui/react';
import Header from './Header';

interface IProps {
  children: React.ReactNode;
  hasSidebar?: boolean;
}

export default function PageLayout({
  children,
  hasSidebar = false,
}: IProps): JSX.Element {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <Box bg="#F7F8FA" minH="100vh">
        <Box position="sticky" top={0} zIndex={100}>
          <Header />
        </Box>

        <Box
          p={4}
          display="grid"
          gridTemplateRows="auto min-content"
          minH="calc(100vh - 60px)"
        >
          {children}
        </Box>
      </Box>
    );
  }

  return (
    <Box bg="#F7F8FA" minH="100vh">
      <Header />

      <Box
        py={hasSidebar ? undefined : 4}
        px={hasSidebar ? undefined : 6}
        display="grid"
        gridTemplateRows="auto min-content"
        minH="calc(100vh - 60px)"
      >
        {children}
      </Box>
    </Box>
  );
}
