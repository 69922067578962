import { useMemo, useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  useToast,
  Select,
} from '@chakra-ui/react';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useFetchPersonnelUsers } from '../../hooks';
import { IWorkItem, UserStatus } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  workItem: IWorkItem;
}

export default function AddPersonnelForm({
  isOpen,
  onClose,
  workItem,
}: IProps): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [personnel] = useFetchPersonnelUsers();
  const [personnelId, setPersonnelId] = useState<string>('');
  const activePersonnel = useMemo(() => {
    return personnel.filter((person) => person.status === UserStatus.ACTIVE);
  }, [personnel]);

  const toast = useToast();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const userRef = doc(db, `users/${personnelId}`);

    try {
      await updateDoc(userRef, {
        workItems: arrayUnion(workItem.id),
        projects: arrayUnion(workItem.projectId),
      });

      toast({
        title: 'Success',
        description: 'Personnel added to work item.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while adding personnel to work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add Personnel</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <FormControl isRequired>
              <FormLabel fontSize="sm">Personnel</FormLabel>
              <Select
                placeholder="Select Personnel"
                onChange={({ currentTarget }) =>
                  setPersonnelId(currentTarget.value)
                }
              >
                {activePersonnel.map((person) => (
                  <option key={person.uid} value={person.uid}>
                    {person.firstName} {person.lastName} -{' '}
                    {`${person.trade
                      .charAt(0)
                      .toUpperCase()}${person.trade.slice(1)}`}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Box
              display="flex"
              gap={3}
              justifyContent="flex-end"
              marginTop="auto"
              pb={4}
            >
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Add Personnel
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
