import { useEffect, useState } from 'react';
import { Avatar, Box } from '@chakra-ui/react';
import { getDownloadURL, ref } from 'firebase/storage';
import UpdateUserImageButton from './UpdateUserImageButton';
import { storage } from '../../firebase';
import { LeadTrade } from '../../types';
import { TRADE_COLORS } from '../../constants';

interface Props {
  id: string;
  isEditable?: boolean;
  name: string;
  size?: string;
  trade: LeadTrade;
}

export default function PersonnelAvatar({
  id,
  isEditable = false,
  size = 'md',
  name,
  trade,
}: Props): JSX.Element {
  const [personnelImageUrl, setPersonnelImageUrl] = useState<string>('');

  useEffect(() => {
    async function fetchPersonnelImage() {
      const imageRef = ref(storage, `userImages/${id}`);

      try {
        const url = await getDownloadURL(imageRef);
        setPersonnelImageUrl(url);
      } catch (error) {
        setPersonnelImageUrl('');
      }
    }

    fetchPersonnelImage();
  }, [id]);

  const backgroundColor = TRADE_COLORS[trade];

  return (
    <Box cursor={isEditable ? 'pointer' : undefined} position="relative">
      <Avatar
        bg={backgroundColor}
        color="white"
        name={name}
        size={size}
        src={personnelImageUrl}
      />
      {isEditable && (
        <UpdateUserImageButton onUpdate={setPersonnelImageUrl} uid={id} />
      )}
    </Box>
  );
}
