import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useGetUser } from '../contexts';
import { db } from '../firebase';
import { PersonnelUser } from '../types';

export default function useFetchPersonnelByProject(
  projectId?: string,
): [PersonnelUser[], boolean] {
  const user = useGetUser();
  const [personnel, setPersonnel] = useState<PersonnelUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user.companyId) {
      return;
    }

    let unsubscribe: any;

    const personnelRef = collection(db, 'users');
    const q = query(
      personnelRef,
      where('companyId', '==', user.companyId),
      where('projects', 'array-contains', projectId),
    );

    unsubscribe = onSnapshot(q, (personnelDocs) => {
      const personnel: PersonnelUser[] = [];

      personnelDocs.forEach((doc) => {
        personnel.push({
          uid: doc.id,
          ...doc.data(),
        } as PersonnelUser);
      });

      setPersonnel(personnel);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [user, projectId]);

  return [personnel, isLoading];
}
