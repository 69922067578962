import { NavLink } from 'react-router-dom';
import { Link, Text, useMediaQuery } from '@chakra-ui/react';
import { GridIcon, UsersIcon } from '../../../assets';
import React from 'react';

interface Props {
  page: 'workItems' | 'team';
  projectId: string;
}

const IconByPage: Record<string, React.FC> = {
  workItems: GridIcon,
  team: UsersIcon,
};

const textByPage: Record<string, string> = {
  workItems: 'Work Items',
  team: 'Project Team',
};

const getUrlByPage = (page: string, projectId: string): string => {
  switch (page) {
    case 'workItems':
      return `/projects/${projectId}/items`;
    case 'team':
      return `/projects/${projectId}/team`;
    default:
      return '';
  }
};

export default function NavItem({ page, projectId }: Props): JSX.Element {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const Icon = IconByPage[page];
  const text = textByPage[page];
  const url = getUrlByPage(page, projectId);

  return (
    <Link
      alignItems="center"
      as={NavLink}
      color="navyBlue"
      display="flex"
      end
      fontSize="sm"
      gap={2}
      pl={5}
      py={2}
      to={url}
      _activeLink={{
        bg: isMobile ? undefined : 'navyBlue',
        color: isMobile ? undefined : 'white',
      }}
    >
      <Icon /> <Text textTransform="capitalize">{text}</Text>
    </Link>
  );
}
