import { Box, Spinner, Heading } from '@chakra-ui/react';

export default function Loader(): JSX.Element {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box
        textAlign="center"
        bg="gray.50"
        border="2px solid"
        borderColor="gray.300"
        shadow="md"
        rounded="sm"
        p={12}
      >
        <Spinner size="xl" mb={8} color="navy" speed="0.75s" thickness="3px" />
        <Heading size="md" fontWeight="medium">
          Loading...
        </Heading>
      </Box>
    </Box>
  );
}
