import { useState } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { ConfirmationDialog } from '../';

interface IProps {
  onDelete: () => Promise<void>;
}

export default function DeleteButton({ onDelete }: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function handleDelete() {
    setIsLoading(true);

    await onDelete();

    setIsLoading(false);
  }

  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
      />

      <Button colorScheme="red" h="36px" onClick={onOpen} isLoading={isLoading}>
        Delete
      </Button>
    </>
  );
}
