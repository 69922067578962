import { Box, Text } from '@chakra-ui/react';
import { ItemStatus } from '../../types';
import { getStatusBadgeColor, formatStatusText } from '../../utils';

interface IProps {
  hasBorder?: boolean;
  size?: string;
  status: ItemStatus;
}

export default function StatusBadge({
  hasBorder = true,
  size = 'small',
  status,
}: IProps): JSX.Element {
  const statusText = formatStatusText(status);
  const color = getStatusBadgeColor(status);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      py={1}
      px={2}
      rounded="md"
      border={hasBorder ? '1px solid' : ''}
      borderColor="#CCCCCC"
    >
      <Box bg={color} h={2} w={2} rounded="full" />

      <Text fontSize={size} fontWeight="medium">
        {statusText}
      </Text>
    </Box>
  );
}
