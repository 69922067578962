import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react';
import { AdminUserForm, AdminUsersTable } from '../../../components';
import { PlusIcon } from '../../../assets';

export default function AdminUsers(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <AdminUserForm isOpen={isOpen} onClose={onClose} />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Heading fontWeight="medium" fontSize="2xl">
          Coaches
        </Heading>
        <Button
          leftIcon={<PlusIcon />}
          variant="primary"
          size="sm"
          onClick={onOpen}
        >
          Invite Coach
        </Button>
      </Box>

      <AdminUsersTable />
    </Box>
  );
}
