import { Box, Heading } from '@chakra-ui/react';
import { useGetUser } from '../../../contexts';
import { EditAdminProfileForm, UserAvatarInput } from '../../../components';

export default function Profile(): JSX.Element {
  const user = useGetUser();

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6}
      >
        <Heading fontWeight="medium" fontSize="2xl">
          Profile
        </Heading>
      </Box>

      <Box bg="white" p={6} rounded="md" shadow="md" maxW={512}>
        <Box mb={8}>
          <UserAvatarInput user={user} />
        </Box>

        <EditAdminProfileForm />
      </Box>
    </Box>
  );
}
