import { MenuItem, Text, useToast } from '@chakra-ui/react';
import { resendInvitation } from '../../../api';
import { Send } from '../../../assets';

interface Props {
  uid: string;
}

export default function ResendInvitation({ uid }: Props): JSX.Element {
  const toast = useToast();

  async function handleOnClick() {
    try {
      await resendInvitation(uid);

      toast({
        title: 'Success',
        description: 'Resent invitation.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });
    } catch (error: any) {
      toast({
        title: 'Uh Oh',
        description: 'Failed to resend invitation. Please try again.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <MenuItem
      alignItems="center"
      display="flex"
      gap={2}
      onClick={handleOnClick}
    >
      <Send />
      <Text>Resend Invitation</Text>
    </MenuItem>
  );
}
