import {
  Box,
  Button,
  Heading,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import {
  EmptyState,
  Loader,
  StatusMenu,
  TaskForm,
  TasksTable,
  WorkItemProgressBar,
} from '../../../../components';
import TaskCard from './TaskCard';
import { PlusIcon } from '../../../../assets';
import { useFetchTasksByWorkItem } from '../../../../hooks';
import { IWorkItem, ItemStatus } from '../../../../types';
import { db } from '../../../../firebase';
import { useGetUser } from '../../../../contexts';
import { isAdminUser } from '../../../../utils';

interface IProps {
  workItem: IWorkItem;
}

export default function WorkBreakdownTab({ workItem }: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tasks, isLoading] = useFetchTasksByWorkItem(workItem.id);
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  if (isLoading) {
    return <Loader />;
  }

  async function handleStatusChange(status: ItemStatus) {
    const workItemRef = doc(db, 'workItems', workItem.id);

    await updateDoc(workItemRef, {
      status,
    });
  }

  const showEmptyState = !isLoading && tasks.length === 0;

  return (
    <Box>
      <Box w={256} mb={6}>
        <Box mb={6}>
          <Heading size="sm" fontWeight="medium" mb={2}>
            Status
          </Heading>

          <StatusMenu
            hasBorder
            isDisabled={!isAdmin}
            status={workItem.status}
            onChange={handleStatusChange}
          />
        </Box>

        <Box>
          <Heading size="sm" fontWeight="medium" mb={2}>
            Progress
          </Heading>

          <Box mt={2}>
            <WorkItemProgressBar id={workItem.id} />
          </Box>
        </Box>
      </Box>

      {isAdmin && (
        <TaskForm
          isOpen={isOpen}
          onClose={onClose}
          projectId={workItem.projectId}
          workItemId={workItem.id}
        />
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={[4, 6]}
      >
        <Heading size="sm" fontWeight="medium">
          Tasks
        </Heading>

        {isAdmin && (
          <Button leftIcon={<PlusIcon />} variant="primary" onClick={onOpen}>
            Create Task
          </Button>
        )}
      </Box>

      {showEmptyState && (
        <EmptyState
          description="Begin by creating a task."
          heading="No Tasks"
        />
      )}

      {!isMobile && !showEmptyState && <TasksTable tasks={tasks} />}
      {isMobile && !showEmptyState && (
        <Box>
          {tasks.map((task) => (
            <Box key={task.id} mb={4}>
              <TaskCard task={task} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
