import { Box, Text } from '@chakra-ui/react';
import { SettingsTabs } from '.';
import { useGetUser } from '../../contexts';
import { isAdminUser } from '../../utils';
import { Phone, Settings, User, UsersIcon } from '../../assets';

interface Props {
  onChangeTab: (tab: SettingsTabs) => void;
  tab: string;
}

export default function SettingsSidebar({
  onChangeTab,
  tab,
}: Props): JSX.Element {
  const user = useGetUser();
  const { role } = user;
  const isAdmin = isAdminUser(role);

  return (
    <Box borderRight="1px solid" borderColor="#CCCCCC" bg="white">
      <Box
        alignItems="center"
        borderBottom="1px solid"
        borderColor="#CCCCCC"
        display="flex"
        gap={2}
        fontSize="lg"
        p={4}
      >
        <Settings />
        <Text>Settings</Text>
      </Box>

      <Box p={4}>
        {isAdmin && (
          <Box
            alignItems="center"
            bg={tab === SettingsTabs.Profile ? 'gray.100' : undefined}
            cursor="pointer"
            display="flex"
            gap={1.5}
            mb={2}
            p={2}
            px={3}
            rounded="md"
            _hover={{
              background: 'gray.50',
            }}
            onClick={() => onChangeTab(SettingsTabs.Profile)}
          >
            <User /> <Text>Profile</Text>
          </Box>
        )}

        {isAdmin && (
          <Box
            alignItems="center"
            bg={tab === SettingsTabs.AdminUsers ? 'gray.100' : undefined}
            cursor="pointer"
            display="flex"
            gap={1.5}
            mb={2}
            p={2}
            px={3}
            rounded="md"
            _hover={{
              background: 'gray.50',
            }}
            onClick={() => onChangeTab(SettingsTabs.AdminUsers)}
          >
            <UsersIcon /> <Text>Coaches</Text>
          </Box>
        )}

        <Box
          alignItems="center"
          bg={tab === SettingsTabs.SmsLogin ? 'gray.100' : undefined}
          cursor="pointer"
          display="flex"
          gap={1.5}
          p={2}
          px={3}
          rounded="md"
          _hover={{
            background: 'gray.50',
          }}
          onClick={() => onChangeTab(SettingsTabs.SmsLogin)}
        >
          <Phone /> <Text>SMS Login</Text>
        </Box>
      </Box>
    </Box>
  );
}
