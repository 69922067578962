import { Box, Image, Link, useMediaQuery } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import AvatarMenu from './AvatarMenu';
import MobileMenu from './MobileMenu';
import { useGetUser } from '../../contexts';
import { isAdminUser } from '../../utils';
import { keelblokLogo, ProjectsIcon, UsersIcon } from '../../assets/';

export default function Header(): JSX.Element {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  return (
    <Box
      bg="white"
      px={[4, 10]}
      py={[2, 3]}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      h="60px"
      borderBottom="1px solid"
      borderColor="#CCCCCC"
    >
      <Box display="flex" gap={12}>
        <NavLink to="/projects">
          <Image src={keelblokLogo} h={[6, '30px']} />
        </NavLink>

        <Box display={['none', 'flex']} gap={6} fontSize="sm" color="navyBlue">
          <Link
            as={NavLink}
            to="/projects"
            display="flex"
            alignItems="center"
            gap={1}
            _activeLink={{
              fontWeight: 'bold',
            }}
          >
            <ProjectsIcon />
            Projects
          </Link>

          {isAdmin && (
            <Link
              as={NavLink}
              to="/team"
              display="flex"
              alignItems="center"
              gap={1}
              _activeLink={{
                fontWeight: 'bold',
              }}
            >
              <UsersIcon />
              Team
            </Link>
          )}
        </Box>
      </Box>

      {isMobile ? <MobileMenu /> : <AvatarMenu />}
    </Box>
  );
}
