import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableRow from './TableRow';
import { useFetchAdminUsers } from '../../../hooks';

export default function AdminUsersTable(): JSX.Element {
  const [adminUsers] = useFetchAdminUsers();

  return (
    <TableContainer bg="white" rounded="md" shadow="md">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Role</Th>
            <Th>Badge Number</Th>
            <Th>Status</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {adminUsers.map((user) => (
            <TableRow key={user.uid} user={user} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
