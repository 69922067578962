import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
} from '@chakra-ui/react';

import { EditableRow } from '../';
import { PlusIcon } from '../../assets';

interface IProps {
  onChange?: (subtasks: string[]) => void;
  initialValues?: string[];
}

export default function SubtaskForm({
  onChange,
  initialValues = [],
}: IProps): JSX.Element {
  const [value, setValue] = useState<string>('');
  const [subtasks, setSubtasks] = useState<string[]>(initialValues);
  const [showForm, setShowForm] = useState<boolean>(false);

  const showEmptyState = subtasks.length === 0 && !showForm;

  useEffect(() => {
    if (onChange) {
      onChange(subtasks);
    }
  }, [onChange, subtasks]);

  function handleSubmit() {
    const newSubtasks: string[] = [...subtasks, value];

    setSubtasks(newSubtasks);
    handleHideForm();
  }

  function handleHideForm() {
    setValue('');
    setShowForm(false);
  }

  function handleRemoveItem(idx: number) {
    const subtasksToUpdate = [...subtasks];

    subtasksToUpdate.splice(idx, 1);

    setSubtasks(subtasksToUpdate);
  }

  function handleEditItem(idx: number, newValue: string) {
    const subtasksToUpdate = [...subtasks];

    subtasksToUpdate[idx] = newValue;

    setSubtasks(subtasksToUpdate);
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Heading size="sm">Subtasks ({subtasks.length})</Heading>

        {onChange && (
          <Button
            size="sm"
            leftIcon={<PlusIcon />}
            onClick={() => setShowForm(true)}
            disabled={showForm}
          >
            Add Subtask
          </Button>
        )}
      </Box>

      {showEmptyState && (
        <Text
          color="gray.400"
          border="1px dashed"
          borderColor="gray.300"
          textAlign="center"
          p={2}
          rounded="md"
          fontSize="sm"
        >
          Subtasks will appear here when added.
        </Text>
      )}

      {subtasks.length > 0 && (
        <Box>
          {subtasks.map((subtask, idx) => (
            <Box key={idx} mb={2}>
              <EditableRow
                idx={idx}
                item={subtask}
                onEdit={handleEditItem}
                onRemove={handleRemoveItem}
              />
            </Box>
          ))}
        </Box>
      )}

      {showForm && (
        <Box mb={6}>
          <FormControl isRequired mb={2}>
            <FormLabel fontSize="sm">Subtask Name</FormLabel>
            <Input
              placeholder="Subtask Name"
              value={value}
              onChange={({ currentTarget }) => setValue(currentTarget.value)}
            />
          </FormControl>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="secondary" onClick={handleHideForm}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Subtask
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
