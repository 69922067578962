import { createContext, useContext, useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useGetUser } from './userContext';
import { IProject } from '../types';

const ProjectsContext = createContext<any>(undefined);

interface IProjectsProvider {
  children: React.ReactNode;
}

export default function ProjectsProvider({ children }: IProjectsProvider) {
  const [projects, setProjects] = useState<IProject[]>([]);
  const { companyId } = useGetUser();

  useEffect(() => {
    if (!companyId) {
      return;
    }

    const projectsRef = collection(db, 'projects');
    const q = query(projectsRef, where('companyId', '==', companyId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const projects: IProject[] = [];

      querySnapshot.forEach((doc) => {
        projects.push({
          id: doc.id,
          ...doc.data(),
        } as IProject);
      });

      setProjects(projects);
    });

    return () => unsubscribe();
  }, [companyId]);

  return (
    <ProjectsContext.Provider value={projects}>
      {children}
    </ProjectsContext.Provider>
  );
}

export function useGetProjects() {
  const context: IProject[] = useContext(ProjectsContext);

  if (!context) {
    throw new Error('useGetProjects must be used within ProjectsProvider');
  }

  return context;
}
