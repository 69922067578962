import { Box, Text } from '@chakra-ui/react';
import ProgressBar from '@ramonak/react-progress-bar';
import { useFetchTasksByWorkItem } from '../../hooks';
import { workItemCompletionRatio } from '../../utils';

interface IProps {
  id: string;
}

export default function WorkItemProgressBar({ id }: IProps): JSX.Element {
  const [tasks] = useFetchTasksByWorkItem(id);
  const completionRatio = workItemCompletionRatio(tasks);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr auto"
      gap={3}
      alignItems="center"
    >
      <ProgressBar
        animateOnRender
        completed={completionRatio}
        borderRadius="2px"
        isLabelVisible={false}
        bgColor="#00205B"
        height="10px"
      />

      <Box display="flex" alignItems="baseline" color="#00205B">
        <Text fontWeight="medium">{completionRatio}</Text>
        <Text fontSize="sm">%</Text>
      </Box>
    </Box>
  );
}
