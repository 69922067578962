import { Box, Image } from '@chakra-ui/react';
import { keelblokLogo, washingtonsHammerLogoColor } from '../../assets';

interface Props {
  children: React.ReactNode;
}

export default function PublicPageLayout({ children }: Props): JSX.Element {
  return (
    <Box
      bg="gray.50"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Box w="512px">
        <Image src={keelblokLogo} h="60px" m="auto" mb={8} />

        <Box bg="white" shadow="md" p={[4, 10]} mb={12} rounded="md">
          {children}
        </Box>

        <Box>
          <Image src={washingtonsHammerLogoColor} h={9} m="auto" />
        </Box>
      </Box>
    </Box>
  );
}
