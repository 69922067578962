import { MenuItem, Text, useToast } from '@chakra-ui/react';
import { updateUserStatus } from '../../../api';
import { Activity } from '../../../assets';
import { AdminUser, PersonnelUser, UserStatus } from '../../../types';

interface Props {
  user: AdminUser | PersonnelUser;
}

export default function UpdateStatus({ user }: Props): JSX.Element {
  const toast = useToast();
  const { status } = user;

  async function handleOnClick() {
    try {
      const newStatus =
        status === UserStatus.ACTIVE ? UserStatus.INACTIVE : UserStatus.ACTIVE;
      await updateUserStatus(user.uid, newStatus);

      toast({
        title: 'Success',
        description: `User has been ${
          status === UserStatus.ACTIVE ? 'deactivated' : 'activated'
        }.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });
    } catch (error: any) {
      toast({
        title: 'Uh Oh',
        description: `Failed to ${
          status === UserStatus.ACTIVE ? 'deactivate' : 'activate'
        } user. Please try again.`,
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <MenuItem
      alignItems="center"
      display="flex"
      gap={2}
      onClick={handleOnClick}
    >
      <Activity />
      <Text>
        {status === UserStatus.ACTIVE ? 'Deactivate' : 'Activate'} User
      </Text>
    </MenuItem>
  );
}
