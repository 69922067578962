import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { IProject } from '../types';

export default function useSubscribeToProject(
  id?: string,
): [IProject | undefined, boolean] {
  const [project, setProject] = useState<IProject>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!id) {
      return;
    }

    const projectRef = doc(db, `projects/${id}`);

    const unsubscribe = onSnapshot(projectRef, (doc) => {
      setProject({
        id: doc.id,
        ...doc.data(),
      } as IProject);

      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [id]);

  return [project, isLoading];
}
