import {
  Box,
  Button,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { EditWorkItemForm, Loader } from '../../../components';
import { Edit } from '../../../assets';
import WorkItemBreadcrumbs from '../WorkItemBreadcrumbs';
import PersonnelTab from './PersonnelTab';
import ResourcesTab from './ResourcesTab';
import WorkBreakdownTab from './WorkBreakdownTab';
import { useSubscribeToWorkItem } from '../../../hooks';
import { useGetUser } from '../../../contexts';
import { isAdminUser } from '../../../utils';

export default function WorkItemPage(): JSX.Element {
  const { id: projectId, workItemId } = useParams();
  const [workItem, isLoading] = useSubscribeToWorkItem(workItemId);
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Box
        mb={6}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <WorkItemBreadcrumbs
          projectId={projectId}
          workItemNumber={workItem ? workItem.number : undefined}
        />

        {workItem && isAdmin && (
          <Button
            leftIcon={<Edit />}
            size="sm"
            variant="outline"
            onClick={onOpen}
          >
            Edit Work Item
          </Button>
        )}
      </Box>

      {workItem && isAdmin && (
        <EditWorkItemForm
          workItem={workItem}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      {isLoading ? (
        <Box>
          <Loader />
        </Box>
      ) : (
        <Box bg="white" rounded="md" shadow="md" p={[2, 4]}>
          <Tabs colorScheme="black">
            <TabList borderBottom="none" mb={4}>
              <Tab fontSize="sm" fontWeight="medium">
                Work Breakdown
              </Tab>
              <Tab fontSize="sm" fontWeight="medium">
                Resources
              </Tab>
              <Tab fontSize="sm" fontWeight="medium">
                Teammates
              </Tab>
            </TabList>

            {workItem && (
              <TabPanels px={1}>
                <TabPanel p={0}>
                  <WorkBreakdownTab workItem={workItem} />
                </TabPanel>
                <TabPanel p={0}>
                  <ResourcesTab workItem={workItem} />
                </TabPanel>
                <TabPanel p={0}>
                  <PersonnelTab workItem={workItem} />
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </Box>
      )}
    </Box>
  );
}
