import { useEffect, useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import { AdminUser, PersonnelUser } from '../../types';

interface Props {
  size: string;
  user: AdminUser | PersonnelUser;
}

export default function UserAvatar({ size = 'md', user }: Props): JSX.Element {
  const [imageUrl, setImageUrl] = useState<string>('');
  const name = `${user.firstName} ${user.lastName}`;

  useEffect(() => {
    async function fetchImage() {
      const imageRef = ref(storage, `userImages/${user.uid}`);

      try {
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (error) {
        setImageUrl('');
      }
    }

    fetchImage();
  }, [user.uid]);

  return <Avatar size={size} name={name} src={imageUrl} />;
}
