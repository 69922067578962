import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import App from './App';
import { ProjectsProvider, UserProvider } from './contexts';
import { theme } from './styles';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://93dbe7274d64de4ddfc333470e0d7d45@o4504121051840512.ingest.us.sentry.io/4507727381790720',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        debug: process.env.NODE_ENV === 'development',
      }}
    >
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <UserProvider>
            <ProjectsProvider>
              <App />
            </ProjectsProvider>
          </UserProvider>
        </ChakraProvider>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
