import { ChangeEvent, useEffect, useState } from 'react';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
  Avatar,
  Box,
  FormLabel,
  Input,
  SkeletonCircle,
  useToast,
} from '@chakra-ui/react';
import { Edit } from '../../assets';
import { AdminUser, PersonnelUser } from '../../types';
import { storage } from '../../firebase';

interface Props {
  user: AdminUser | PersonnelUser;
}

export default function UserAvatarInput({ user }: Props): JSX.Element {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();

  const name = `${user.firstName} ${user.lastName}`;

  useEffect(() => {
    async function fetchPersonnelImage() {
      const imageRef = ref(storage, `userImages/${user.uid}`);

      try {
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (error) {
        setImageUrl('');
      } finally {
        setIsLoading(false);
      }
    }

    fetchPersonnelImage();
  }, [user]);

  async function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const fileUpload = event.target.files?.[0];

    if (fileUpload) {
      setIsLoading(true);

      const imageSrc = URL.createObjectURL(fileUpload);

      setImageUrl(imageSrc);

      const storageRef = ref(storage, `userImages/${user.uid}`);

      try {
        await uploadBytes(storageRef, fileUpload);

        toast({
          title: 'Success',
          description: 'Successfully uploaded profile picture.',
          status: 'success',
          variant: 'left-accent',
          position: 'top-right',
        });
      } catch (error) {
        toast({
          title: 'Uh Oh',
          description: 'Failed to upload profile picture. Please try again.',
          status: 'error',
          variant: 'left-accent',
          position: 'top-right',
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <SkeletonCircle h="96px" w="96px" mx="auto" isLoaded={!isLoading}>
      <FormLabel htmlFor="profileImage">
        <Box position="relative">
          <Avatar cursor="pointer" size="xl" name={name} src={imageUrl} />
          <Edit
            bottom={1}
            color="white"
            fontSize="2xs"
            position="absolute"
            right="calc(50% - 12px)"
          />
        </Box>
      </FormLabel>
      <Input
        type="file"
        id="profileImage"
        display="none"
        onChange={handleImageUpload}
      />
    </SkeletonCircle>
  );
}
