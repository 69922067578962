import { useMemo } from 'react';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { usePostHog } from 'posthog-js/react';
import { UserAvatar } from '../';
import { useGetUser } from '../../contexts';
import { LogOutIcon, Settings } from '../../assets';
import { auth } from '../../firebase';
import { getRoleName } from '../../utils';

export default function AvatarMenu(): JSX.Element {
  const navigate = useNavigate();
  const user = useGetUser();
  const posthog = usePostHog();
  const { firstName, lastName, role } = user;
  const userRole = useMemo(() => getRoleName(role), [role]);

  async function handleLogout() {
    await signOut(auth);
    posthog?.reset();
    navigate('/');
  }

  return (
    <Menu>
      <MenuButton>
        <Box display="flex" alignItems="center" gap={3}>
          <Box textAlign="left">
            <Text fontWeight="bold" fontSize="sm">
              {firstName} {lastName}
            </Text>

            <Text
              fontWeight="light"
              fontSize="xs"
              color="#9A9A9A"
              textTransform="capitalize"
            >
              {userRole}
            </Text>
          </Box>

          <UserAvatar size="sm" user={user} />
        </Box>
      </MenuButton>

      <MenuList>
        <MenuItem as={Link} to="/settings" display="flex" gap={2}>
          <Settings />
          Settings
        </MenuItem>

        <MenuDivider />
        <MenuItem display="flex" gap={2} onClick={handleLogout}>
          <LogOutIcon /> Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
