import React, { useEffect, useState } from 'react';
import { Box, Button, Link, Text } from '@chakra-ui/react';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';
import { PublicPageLayout } from '../../components';
import EmailLoginForm from './EmailLoginForm';
import SmsLoginForm from './SmsLoginForm';
import { auth } from '../../firebase';

export default function LoginPage(): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showSmsLogin, setShowSmsLogin] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/projects');
      }
    });

    return subscriber();
  }, [navigate]);

  async function handleLogin(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    setHasError(false);
    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);

      navigate('/projects');
    } catch (error: any) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  return (
    <PublicPageLayout>
      {showSmsLogin ? <SmsLoginForm /> : <EmailLoginForm />}

      <Box mb={8}>
        <Box alignItems="center" display="flex" gap={4} my={8}>
          <Box h="2px" bg="gray.300" mt="2px" flex={1} rounded="md" />
          <Text color="gray.400">or</Text>
          <Box h="2px" bg="gray.300" mt="2px" flex={1} rounded="md" />
        </Box>

        <Button onClick={() => setShowSmsLogin(!showSmsLogin)} w="100%">
          Login with {showSmsLogin ? 'Email' : 'SMS'}
        </Button>
      </Box>

      <Box fontSize="sm" textAlign="center">
        Forgot your password?{' '}
        <Link
          as={ReactRouterLink}
          to="/request-password-reset"
          color="navyBlue"
          fontWeight="semibold"
        >
          Reset it here
        </Link>
        .
      </Box>
    </PublicPageLayout>
  );
}
