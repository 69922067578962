import { Box, Text } from '@chakra-ui/react';
import ProgressBar from '@ramonak/react-progress-bar';
import { useFetchTasksByProject } from '../../hooks';
import { projectCompletionRatio } from '../../utils';

interface IProps {
  id: string;
}

export default function ProjectProgressBar({ id }: IProps): JSX.Element {
  const [tasks] = useFetchTasksByProject(id);
  const completionRatio = projectCompletionRatio(tasks);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr auto"
      gap={3}
      alignItems="center"
    >
      <ProgressBar
        animateOnRender
        completed={completionRatio}
        borderRadius="2px"
        isLabelVisible={false}
        bgColor="#00205B"
        height="6px"
      />

      <Box display="flex" alignItems="baseline" color="#00205B">
        <Text fontSize="sm" fontWeight="medium">
          {completionRatio}
        </Text>
        <Text fontSize="xs">%</Text>
      </Box>
    </Box>
  );
}
