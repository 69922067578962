import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useFetchTasksByProject } from '../../hooks';
import { projectCompletionRatio } from '../../utils';

interface IProps {
  id: string;
}

export default function ProjectProgressCircle({ id }: IProps): JSX.Element {
  const [tasks, isLoading] = useFetchTasksByProject(id);
  const completionRatio = projectCompletionRatio(tasks);

  return (
    <CircularProgress
      isIndeterminate={isLoading}
      value={completionRatio}
      color="navyBlue"
      thickness={4}
      size={150}
    >
      <CircularProgressLabel fontSize="md">
        {completionRatio}%
      </CircularProgressLabel>
    </CircularProgress>
  );
}
