import { PersonnelUser, LeadTrade } from '../../types';

interface IFilterPersonnelParams {
  personnel: PersonnelUser[];
  searchValue: string;
  tradeFilter?: LeadTrade;
}

export function filterPersonnel({
  personnel,
  searchValue,
  tradeFilter,
}: IFilterPersonnelParams): PersonnelUser[] {
  return personnel
    .filter((person) => {
      if (searchValue) {
        const name = `${person.firstName} ${person.lastName}`.toLowerCase();

        return name.toLowerCase().includes(searchValue);
      } else {
        return person;
      }
    })
    .filter((person) => {
      if (tradeFilter) {
        return person.trade === tradeFilter;
      } else {
        return person;
      }
    });
}
