import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  Select,
} from '@chakra-ui/react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { DeleteButton } from '../';
import { db } from '../../firebase';
import SubtaskForm from './SubtaskForm';
import { LeadTrade, ITask, ItemStatus, ISubtask } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  task: ITask;
}

function formatSubtasks(
  initialTasks: ISubtask[],
  newTaskNames: string[],
): ISubtask[] {
  return newTaskNames.map((name) => {
    const initialTask = initialTasks.find((task) => task.name === name);

    return {
      name,
      status: initialTask ? initialTask.status : ItemStatus.NOT_STARTED,
    } as ISubtask;
  });
}

export default function EditTaskForm({
  isOpen,
  onClose,
  task,
}: IProps): JSX.Element {
  const [name, setName] = useState<string>(task.name);
  const [number, setNumber] = useState<string>(String(task.number));
  const [leadTrade, setLeadTrade] = useState<LeadTrade>(task.leadTrade);
  const [hoursAllocated, setHoursAllocated] = useState<string>(
    String(task.hoursAllocated),
  );
  const [subtasks, setSubtasks] = useState<string[]>(
    task.subtasks.map((subtask) => subtask.name),
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const toast = useToast();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const taskRef = doc(db, `/tasks/${task.id}`);

    try {
      await updateDoc(taskRef, {
        name,
        number: Number(number),
        leadTrade,
        hoursAllocated: Number(hoursAllocated),
        subtasks: formatSubtasks(task.subtasks, subtasks),
      } as Omit<ITask, 'id' | 'createdAt' | 'workItemId'>);

      toast({
        title: 'Success',
        description: `Task ${name} was updated.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while updating the task.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function handleDelete() {
    setIsDeleting(true);

    try {
      const taskRef = doc(db, `/tasks/${task.id}`);

      await deleteDoc(taskRef);

      toast({
        title: 'Success',
        description: `Task ${name} was deleted.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while deleting the task.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Task</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <VStack spacing={2} mb={6}>
              <FormControl isRequired>
                <FormLabel fontSize="sm">Task Name</FormLabel>
                <Input
                  value={name}
                  placeholder="Task Name"
                  onChange={({ currentTarget }) => setName(currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Task Number</FormLabel>
                <Select
                  value={number}
                  placeholder="Task Number"
                  onChange={({ currentTarget }) =>
                    setNumber(currentTarget.value)
                  }
                >
                  {[...Array(20)].map((_, idx) => (
                    <option key={idx} value={idx + 1}>
                      {idx + 1}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Lead Trade</FormLabel>
                <Select
                  placeholder="Select Lead Trade"
                  value={leadTrade}
                  onChange={({ currentTarget }) =>
                    setLeadTrade(currentTarget.value as LeadTrade)
                  }
                >
                  {Object.values(LeadTrade).map((trade) => (
                    <option key={trade} value={trade}>
                      {`${trade.charAt(0).toUpperCase()}${trade.slice(1)}`}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Hours Allocated</FormLabel>
                <Input
                  type="number"
                  placeholder="Hours Allocated"
                  value={hoursAllocated}
                  onChange={({ currentTarget }) =>
                    setHoursAllocated(currentTarget.value)
                  }
                />
              </FormControl>
            </VStack>

            <SubtaskForm
              initialValues={subtasks}
              onChange={(subtasks) => setSubtasks(subtasks)}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              marginTop="auto"
              pb={4}
            >
              <DeleteButton onDelete={handleDelete} />

              <Box>
                <Button
                  variant="secondary"
                  onClick={onClose}
                  disabled={isSubmitting}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                  disabled={isDeleting}
                >
                  Save Task
                </Button>
              </Box>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
