import { useEffect, useState } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { PatternFormat } from 'react-number-format';
import { smsLoginPreflight } from '../../api';
import { formatPhoneNumberInput } from '../../utils';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';

export default function SmsLoginForm(): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response: any) => {},
      },
      auth,
    );
  }, []);

  async function handleOnSendSms() {
    setErrorMsg(undefined);

    if (!validatePhoneNumberInput(phoneNumber)) {
      setErrorMsg('Please enter a valid phone number.');
      return;
    }

    const formattedPhoneNumber = formatPhoneNumberInput(phoneNumber);

    setIsLoading(true);

    try {
      await smsLoginPreflight(formattedPhoneNumber);

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        // @ts-ignore
        window.recaptchaVerifier,
      );

      setConfirmationResult(confirmationResult);
    } catch (error: any) {
      console.log(error);
      setErrorMsg(
        'Failed to send SMS. This may be because there is no account associated with this number or you have exceeded the number of attempts. Please login using your email and password instead.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleOnConfirmCode() {
    setErrorMsg(undefined);
    setIsLoading(true);

    try {
      await confirmationResult.confirm(code);

      navigate('/projects');
    } catch (error: any) {
      console.log(error);
      setErrorMsg(
        'Code verification failed. Please double check the code and try again.',
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box>
      <Text
        color="navyBlue"
        fontSize="lg"
        fontWeight="semibold"
        mb={6}
        textAlign="center"
      >
        Login with SMS
      </Text>

      {errorMsg && (
        <Text
          bg="red.50"
          border="2px solid"
          borderColor="red.500"
          color="red"
          fontSize="sm"
          mb={4}
          p={1}
          px={2}
          rounded="md"
        >
          <Text fontWeight="semibold" as="span" mr={1}>
            Uh Oh.
          </Text>
          {errorMsg}
        </Text>
      )}

      {confirmationResult ? (
        <Box>
          <FormControl isRequired mb={4}>
            <FormLabel fontSize="sm">Verification Code</FormLabel>
            <Input
              placeholder="Verification Code"
              onChange={({ currentTarget }) => setCode(currentTarget.value)}
            />
          </FormControl>

          <Button
            id="sign-in-button"
            isLoading={isLoading}
            onClick={handleOnConfirmCode}
            variant="primary"
            w="100%"
          >
            Confirm Code
          </Button>
        </Box>
      ) : (
        <Box>
          <FormControl isRequired mb={4}>
            <FormLabel fontSize="sm">Phone Number</FormLabel>
            <Input
              as={PatternFormat}
              format="+1 ###-###-####"
              isRequired
              placeholder="Phone Number"
              onChange={({ currentTarget }) =>
                setPhoneNumber(currentTarget.value)
              }
            />
          </FormControl>

          <Button
            id="sign-in-button"
            isLoading={isLoading}
            onClick={handleOnSendSms}
            variant="primary"
            w="100%"
          >
            Send SMS
          </Button>
        </Box>
      )}
    </Box>
  );
}

function validatePhoneNumberInput(value: string): boolean {
  const phoneNumber = formatPhoneNumberInput(value);

  return phoneNumber.length === 12;
}
