import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  Select,
} from '@chakra-ui/react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import SubtaskForm from './SubtaskForm';
import { useGetUser } from '../../contexts';
import { LeadTrade, ITask, ItemStatus } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  workItemId: string;
}

export default function TaskForm({
  isOpen,
  onClose,
  projectId,
  workItemId,
}: IProps): JSX.Element {
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [leadTrade, setLeadTrade] = useState<string>('');
  const [hoursAllocated, setHoursAllocated] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [subtasks, setSubtasks] = useState<string[]>([]);

  const toast = useToast();
  const user = useGetUser();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const tasksRef = collection(db, 'tasks');

    try {
      await addDoc(tasksRef, {
        createdAt: new Date(),
        createdBy: user.uid,
        companyId: user.companyId,
        name,
        number: Number(number),
        leadTrade,
        hoursAllocated: Number(hoursAllocated),
        progress: 0,
        projectId,
        status: ItemStatus.NOT_STARTED,
        subtasks: subtasks.map((subtask, idx) => ({
          id: `${workItemId}_${idx}`,
          name: subtask,
          status: ItemStatus.NOT_STARTED,
        })),
        workItemId,
      } as Omit<ITask, 'id' | 'createdAt'>);

      toast({
        title: 'Success',
        description: `Task ${name} was created.`,
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while creating the task.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create Task</DrawerHeader>

        <DrawerBody>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <VStack spacing={2} mb={6}>
              <FormControl isRequired>
                <FormLabel fontSize="sm">Task Name</FormLabel>
                <Input
                  placeholder="Task Name"
                  onChange={({ currentTarget }) => setName(currentTarget.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Task Number</FormLabel>
                <Select
                  placeholder="Task Number"
                  onChange={({ currentTarget }) =>
                    setNumber(currentTarget.value)
                  }
                >
                  {[...Array(20)].map((_, idx) => (
                    <option key={idx} value={idx + 1}>
                      {idx + 1}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Lead Trade</FormLabel>
                <Select
                  placeholder="Select Lead Trade"
                  onChange={({ currentTarget }) =>
                    setLeadTrade(currentTarget.value)
                  }
                >
                  {Object.values(LeadTrade).map((trade) => (
                    <option key={trade} value={trade}>
                      {`${trade.charAt(0).toUpperCase()}${trade.slice(1)}`}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel fontSize="sm">Hours Allocated</FormLabel>
                <Input
                  type="number"
                  placeholder="Hours Allocated"
                  onChange={({ currentTarget }) =>
                    setHoursAllocated(currentTarget.value)
                  }
                />
              </FormControl>
            </VStack>

            <SubtaskForm onChange={(subtasks) => setSubtasks(subtasks)} />

            <Box
              display="flex"
              gap={3}
              justifyContent="flex-end"
              marginTop="auto"
              pb={4}
            >
              <Button
                variant="secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting}>
                Save Task
              </Button>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
