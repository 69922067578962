import { useState, SyntheticEvent } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { DeleteButton } from '../';
import { db } from '../../firebase';
import { IWorkItem } from '../../types';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  workItem: IWorkItem;
}

export default function EditWorkItemForm({
  isOpen,
  onClose,
  workItem,
}: IProps): JSX.Element {
  const [workItemNumber, setWorkItemNumber] = useState<string>(workItem.number);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const toast = useToast();

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsSubmitting(true);

    const workItemRef = doc(db, 'workItems', workItem.id);

    try {
      await updateDoc(workItemRef, {
        number: workItemNumber,
      } as Pick<IWorkItem, 'number'>);

      toast({
        title: 'Success',
        description: 'Work item updated.',
        status: 'success',
        variant: 'left-accent',
        position: 'top-right',
      });

      onClose();
    } catch (error) {
      setIsSubmitting(false);

      toast({
        title: 'Uh Oh',
        description: 'An error occurred while updating the work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  async function handleDelete() {
    const workItemRef = doc(db, 'workItems', workItem.id);

    try {
      await deleteDoc(workItemRef);

      navigate(`/projects/${workItem.projectId}`);
    } catch (error) {
      toast({
        title: 'Uh Oh',
        description: 'An error occurred while deleting the work item.',
        status: 'error',
        variant: 'left-accent',
        position: 'top-right',
      });
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Edit Work Item</DrawerHeader>

        <DrawerBody pb={4}>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
          >
            <FormControl isRequired mb={4}>
              <FormLabel>Work Item Number</FormLabel>
              <Input
                value={workItemNumber}
                placeholder="Work Item Number"
                onChange={({ currentTarget }) =>
                  setWorkItemNumber(currentTarget.value)
                }
              />
            </FormControl>

            <Box display="flex" justifyContent="space-between" mt="auto">
              <DeleteButton onDelete={handleDelete} />

              <Box>
                <Button
                  variant="secondary"
                  onClick={onClose}
                  disabled={isSubmitting}
                  mr={2}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                >
                  Save Work Item
                </Button>
              </Box>
            </Box>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
