import { createContext, useContext, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { usePostHog } from 'posthog-js/react';
import { auth, db } from '../firebase';
import { AdminUser, PersonnelUser } from '../types';

const UserContext = createContext<any>(undefined);

interface UserProvider {
  children: React.ReactNode;
}

type User = AdminUser & PersonnelUser;

export default function UserProvider({ children }: UserProvider) {
  const [userUid, setUserUid] = useState<string | null>(null);
  const [user, setUser] = useState<User | {}>({});
  const posthog = usePostHog();

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserUid(user.uid);

        posthog?.identify(user.uid, {
          email: user.email,
        });
      } else {
        posthog?.reset();
        setUserUid(null);
        setUser({});
      }
    });

    return () => subscriber();
  }, []);

  useEffect(() => {
    async function fetchUser() {
      if (!userUid) {
        return;
      }

      const userRef = doc(db, 'users', userUid);
      const userDoc = await getDoc(userRef);

      setUser({
        uid: userUid,
        ...userDoc.data(),
      } as User);
    }

    fetchUser();
  }, [userUid]);

  const value: User | {} = {
    ...user,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useGetUser() {
  const context: User = useContext(UserContext);

  if (!context) {
    throw new Error('useGetUser must be used within UserProvider');
  }

  return context;
}

export { useGetUser };
